body {
	font-family: Quicksand, sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 400;
}

.page-wrapper {
	color: #444;
	display: block;
}

#printHeader,
#printFooter {
	display: none;
}

h1 {
	margin-top: 0rem;
	margin-bottom: 0.5rem;
	font-family: 'Crete Round', sans-serif;
	color: #2b4972;
	font-size: 2.35rem;
	line-height: 2.85rem;
	font-weight: 400;
	letter-spacing: 0.01rem;
	text-transform: none;
}

h2 {
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	font-family: 'Crete Round', sans-serif;
	color: #2b4972;
	font-size: 1.875rem;
	line-height: 2.375rem;
	font-weight: 400;
	letter-spacing: 0.01rem;
}

h3 {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	font-family: 'Crete Round', sans-serif;
	color: #2b4972;
	font-size: 1.5rem;
	line-height: 2rem;
	font-weight: 400;
	letter-spacing: 0.01rem;
}

h4 {
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	font-family: 'Crete Round', sans-serif;
	color: #2b4972;
	font-size: 1.25rem;
	line-height: 1.75rem;
	font-weight: 400;
	letter-spacing: 0.01rem;
}

h5 {
	margin-top: 1.375rem;
	margin-bottom: 0.75rem;
	font-family: 'Crete Round', sans-serif;
	color: #2b4972;
	font-size: 0.9375rem;
	line-height: 1.35rem;
	font-weight: 400;
	letter-spacing: 0.01rem;
	text-transform: none;
}

h6 {
	margin-top: 1.5rem;
	margin-bottom: 0.875rem;
	font-family: 'Crete Round', sans-serif;
	color: #2b4972;
	font-size: 0.8125rem;
	line-height: 1.2rem;
	font-weight: 400;
	letter-spacing: 0.01rem;
}

p {
	margin-top: 0rem;
	margin-bottom: 0.75rem;
	font-family: Quicksand, sans-serif;
}

body a {
	padding-bottom: 0.05rem;
	border-bottom: 1px solid rgba(73, 143, 163, 0.35);
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
	color: #498fa3;
	background-color: transparent;
	text-decoration: none;
}

body a:hover {
	color: #28313b;
	background-color: transparent;
	text-decoration: none;
}

ul {
	margin-top: 0.5rem;
	margin-bottom: 0.75rem;
	padding-left: 1.25rem;
	font-family: Quicksand, sans-serif;
}

ol {
	margin-top: 0.5rem;
	margin-bottom: 0.75rem;
	padding-left: 1.125rem;
	font-family: Quicksand, sans-serif;
}

li {
	margin-bottom: 0.25rem;
}

label {
	display: block;
	margin-bottom: 0.25rem;
	line-height: 1.25em;
	font-weight: 700;
}

strong {
	font-weight: 700;
	letter-spacing: 0.02em;
}

em {
	font-style: italic;
	letter-spacing: 0.02em;
}

blockquote {
	margin-top: 1em;
	margin-bottom: 1em;
	padding: 1.25em;
	border-left: 4px solid rgba(0, 0, 0, 0.5);
	background-color: rgba(0, 0, 0, 0.05);
	font-size: 1.0625rem;
	line-height: 1.5rem;
}

.button,
.primary,
.secondary,
.tertiary,
button[type="submit"] {
	display: inline-block;
	margin-top: 0.5rem;
	margin-right: 1rem;
	margin-bottom: 0.5rem;
	padding: 0.5rem 3rem 0.5rem 1.5rem;
	background-color: #27758a;
	background-image: url('/20180504170300/assets/images/button_arrow-pointing-right-02.svg');
	background-position: 90% 50%;
	background-size: 25px 25px;
	background-repeat: no-repeat;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #fff;
	font-weight: 400;
	text-align: center;
	letter-spacing: 0.05rem;
	text-decoration: none;
	cursor: pointer;
}

.primary,
.secondary,
.tertiary,
button[type="submit"] {
	padding: 0.5rem 3rem 0.5rem 1.5rem !important;
	border: 0 none !important;
}

.left-sidebar-custom a.primary,
.inside-row-nested a.primary,
.left-sidebar-custom a.secondary,
.inside-row-nested a.secondary,
.left-sidebar-custom a.tertiary,
.inside-row-nested a.tertiary {
	color: #fff;
	font-weight: 400;
}

.button:hover,
a.primary:hover,
.primary:hover,
.secondary:hover,
.tertiary:hover,
button[type="submit"]:hover {
	background-color: #2e5f6c !important;
	color: #fff;
	text-decoration: none;
}

.left-sidebar-custom a.primary:hover,
.inside-row-nested a.primary:hover,
.left-sidebar-custom a.secondary:hover,
.inside-row-nested a.secondary:hover,
.left-sidebar-custom a.tertiary:hover,
.inside-row-nested a.tertiary:hover {
	color: #fff;
	text-decoration: none;
	font-weight: 400;
}

.button.secondary {
	display: inline-block;
	background-color: #2e5f6c;
	background-position: 90% 50%;
}

.secondary,
button[type="submit"].secondary {
	background-color: #2e5f6c !important;
	background-position: 90% 50%;
}

.button.secondary:hover,
.secondary:hover,
button[type="submit"].secondary:hover {
	background-color: #498fa3 !important;
	background-position: 90% 50%;
}

.button.tertiary {
	padding: 0.35rem 1.25rem 0.35em;
	background-color: #09182e;
	background-image: none;
}

.tertiary,
button[type="submit"].tertiary {
	padding: 0.35rem 1.25rem 0.35em !important;
	background-color: #09182e !important;
	background-image: none;
}

.button.tertiary:hover,
.tertiary:hover,
button[type="submit"].tertiary:hover {
	background-color: #1b365a !important;
	background-image: none;
}

.right-sidebar-column .primary,
.right-sidebar-column .secondary,
.right-sidebar-column .tertiary,
.right-sidebar-column button[type="submit"],
.button.right-sidebar-button {
	font-size: 1rem;
}

.button.header-search-button {
	position: absolute;
	top: 0px;
	right: 0px;
	width: 2.5rem;
	height: 100%;
	min-height: 2.25rem;
	margin-top: 0rem;
	margin-right: 0px;
	margin-bottom: 0rem;
	padding-top: 0rem;
	padding-bottom: 0rem;
	float: right;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	background-color: #09182e;
	background-image: url('/20180504170300/assets/images/search.svg');
	background-position: 50% 50%;
	background-size: 1em 1em;
	background-repeat: no-repeat;
	box-shadow: inset 0 0 10px 1px rgba(0, 0, 0, 0.5);
	color: transparent;
	font-size: 0.8125rem;
	line-height: 1em;
	text-transform: uppercase;
}

.button.header-search-button:hover {
	background-color: #000 !important;
	text-transform: uppercase;
}

.button.header-search-button._2 {
	display: none;
	width: 4.5rem;
	height: 4.5rem;
	padding-left: 2em;
	background-color: #000;
	background-size: 20px 20px;
}

.button.slider-button {
	display: block;
	width: 135px;
	margin: 0.5em auto;
	padding: 0.35em 1em;
	border: 2px solid transparent;
	background-color: rgba(73, 143, 163, 0.5);
	font-family: Quicksand, sans-serif;
	font-size: 0.9rem;
	font-weight: 400;
	text-align: center;
	text-transform: uppercase;
}

.button.slider-button:hover {
	border: 2px solid #fff;
	border-radius: 0px;
	background-color: transparent;
	background-image: -webkit-linear-gradient(270deg, rgba(73, 143, 163, 0.5), rgba(73, 143, 163, 0.5));
	background-image: linear-gradient(180deg, rgba(73, 143, 163, 0.5), rgba(73, 143, 163, 0.5));
}

.button.learn-more {
	background-position: 91% 50%;
}

.button.subscribe {
	margin-top: 0rem;
	margin-right: 0rem;
	margin-bottom: 1rem;
	float: right;
	background-position: 95% 50%;
}

.button.subscribe.details {
	float: none;
}

.nav-section {
	position: fixed;
	width: 100%;
	height: 205px;
	background-color: #0e213b;
	box-shadow: inset 0 1px 5px 2px rgba(0, 0, 0, 0.5);
	color: #fff;
	top: 0;
	z-index: 1;
}

.nav-container {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 3.5rem;
	max-width: 1400px;
	margin-top: 1rem;
}

.nav-drop-wrap .nav-drop-toggle-link {
	border-bottom: 5px solid transparent;
	box-shadow: none;
	font-family: Quicksand, sans-serif;
	color: #fff;
	font-size: 1rem;
	line-height: 1.375em;
	font-weight: 400;
	text-align: center;
	text-transform: uppercase;
	height: 100%;
}

.nav-link {
	display: inline-block;
	margin-right: 0px;
	padding: 1rem 1.5rem;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	border-bottom: 5px solid transparent;
	box-shadow: none;
	font-family: Quicksand, sans-serif;
	color: #fff;
	font-size: 1rem;
	line-height: 1.375em;
	font-weight: 400;
	text-align: center;
	text-transform: uppercase;
}

.nav-drop-wrap .nav-drop-toggle-link:hover,
.nav-link:hover {
	margin-right: 0px;
	border-bottom-color: #48bdd7;
	background-color: rgba(0, 0, 0, 0.1);
	box-shadow: none;
	color: #fff;
}

.nav-drop-wrap .nav-drop-toggle-link.active-item,
.nav-link.w--current {
	margin-right: 0px;
	padding-right: 1rem;
	padding-left: 1rem;
	border-bottom-style: none;
	background-color: #000;
	box-shadow: none;
	font-family: Quicksand, sans-serif;
	color: #fff;
	font-size: 1rem;
	text-transform: uppercase;
}

.nav-drop-wrap .nav-drop-toggle-link.active-item:hover .nav-link.w--current:hover {
	background: #000;
	color: #fff;
}

.nav-menu-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 3.5rem;
	margin-top: 0px;
	float: left;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-left: 1px none hsla(0, 0%, 50%, 0.25);
}

ul.nav-menu-wrap {
	margin: 0;
	padding: 0;
}

.logo {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 1rem;
	margin-bottom: 1rem;
	margin-left: 1rem;
	float: left;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	border-bottom-style: none;
	color: #fff;
	font-size: 1.625rem;
	line-height: 1.25rem;
	font-weight: 600;
}

.logo:hover {
	color: #fff;
	font-weight: 600;
}

.logo.w--current {
	margin-top: 1rem;
	margin-bottom: 1rem;
	margin-left: 0.75rem;
	padding-bottom: 0.15rem;
	-webkit-transition-duration: 500ms;
	transition-duration: 500ms;
}

.footer-section {
	position: relative;
	padding-top: 2rem;
	background-color: #142b4c;
	color: hsla(0, 0%, 100%, 0.5);
	font-size: 0.875rem;
	line-height: 1.375rem;
}

.row.footer-signoff-row {
	display: block;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 2rem;
	padding-left: 2rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.row.footer-row {
	display: block;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.column {
	padding-right: 0px;
	padding-left: 0px;
}

.logo-text {
	float: left;
	font-family: 'Crete Round', sans-serif;
	line-height: 1.65rem;
	font-weight: 400;
	letter-spacing: 1px;
}

.logo-image {
	width: 7rem;
	height: 7rem;
	margin-right: 1.25rem;
	float: left;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
}

.footer-signoff-section {
	margin-top: 1rem;
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
	border-style: none;
	border-width: 2px 1px 1px;
	border-color: #2e5f6c #000 #000;
	background-color: #09182e;
	font-size: 0.75rem;
	line-height: 1rem;
}

.copyright-text {
	display: inline-block;
	margin-right: 0.75rem;
	margin-left: 0rem;
	color: #fff;
	font-family: 'Crete Round', sans-serif;
	font-weight: 400;
}

.footer-signoff-list {
	display: inline-block;
	margin: 0rem 0.25rem;
	padding-left: 0px;
}

.footer-signoff-list-item {
	display: inline-block;
	margin-bottom: 0em;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
}

#GRIPFooterLogo a {
	text-decoration: none;
	border: 0 none;
}

.footer-signoff-grip {
	width: 130px;
	height: 28px;
	padding-left: 0;
	float: right;
	border-bottom-style: none;
	border-bottom-width: 0px;
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #606060;
	font-size: 0.5rem;
	line-height: 0.55625rem;
	font-weight: 600;
	text-transform: uppercase;
}

.footer-column-wrap {
	width: 40%;
	max-width: 40%;
	margin-bottom: 1em;
	padding-right: 1rem;
	float: left;
	clear: none;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-flex-basis: auto;
	-ms-flex-preferred-size: auto;
	flex-basis: auto;
}

.footer-column-wrap._1 {
	width: 45%;
	max-width: 50%;
}

.footer-column-wrap._3 {
	width: 15%;
}

.footer-column-title {
	margin-top: 0.5em;
	font-family: Quicksand, sans-serif;
	color: #fff;
	font-size: 1.125rem;
	font-weight: 400;
	text-transform: uppercase;
}

.header-content-right-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 1rem;
	margin-right: 1rem;
	float: right;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	justify-content: flex-end;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
}

.report-a-fraud-button {
	margin-left: 1rem;
}

.header-section {
	position: relative;
	height: 150px;
	background-color: transparent;
}

.two-part-button-wrap {
	position: relative;
	display: block;
	max-width: 768px;
	margin-right: auto;
	margin-left: auto;
}

.breadcrumb-list {
	margin: 0rem 0.125rem 1rem;
	padding-left: 0px;
	font-size: 0.6875rem;
	line-height: 1em;
	text-transform: uppercase;
}

.breadcrumb-list-item {
	margin-top: 0.25rem;
	margin-right: 0.5rem;
	margin-bottom: 0.25rem;
	float: left;
}

.header-menu-wrap {
	height: 3.5rem;
	margin-top: 0px;
	background-color: #0e213b;
	background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25));
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25));
	box-shadow: 0 1px 5px 2px rgba(0, 0, 0, 0.15);
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
}

.form-wrap {
	margin-bottom: 0px;
}

.form-field {
	position: relative;
	width: auto;
	margin-bottom: 0.75rem;
	padding: 4px 10px;
	font-size: 0.875rem;
}

.form-field.right-sidebar-field {
	width: 100%;
	margin-top: 0.375em;
	margin-bottom: 1.25em;
	padding-right: 4px;
	padding-left: 8px;
}

.form-field.right-sidebar-field.miles-from {
	width: 52%;
}

.form-field.right-sidebar-field.zip-code {
	width: 45%;
}

.form-field.header-search-field {
	width: 300px;
	height: 2.4rem;
	margin-bottom: 0rem;
	padding-right: 3rem;
	float: left;
	-webkit-flex-basis: auto;
	-ms-flex-preferred-size: auto;
	flex-basis: auto;
	border: 0px none transparent;
	border-radius: 0px;
	background-color: transparent;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	color: #fff;
	font-size: 0.75em;
}

.form-field.header-search-field:focus {
	width: 300px;
	border: 0px none transparent;
	background-color: rgba(46, 95, 108, 0.85);
	color: #fff;
}

.form-success {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #377c16;
	color: #fff;
}

.form-error {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #d12c16;
	color: #fff;
}

.secondary-nav-list {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0em;
	margin-bottom: 0rem;
	padding-left: 0rem;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	font-size: 1rem;
	line-height: 1.25rem;
}

.secondary-nav-list-item {
	margin-bottom: 0em;
	float: left;
	border-left: 1px solid rgba(0, 0, 0, 0.2);
	letter-spacing: 0.01em;
}

.breadcrumb-divider {
	margin-left: 0.5rem;
	float: right;
}

.left-nav-column {
	padding-left: 0px;
}

.left-nav-list {
	margin-top: 0rem;
	margin-bottom: 1.5rem;
	padding-left: 0px;
	box-shadow: none;
}

.left-nav-list-item {
	margin-bottom: 0rem;
	box-shadow: none;
}

.left-nav-list-link {
	display: block;
	padding: 0.75rem 1.5rem 0.75rem 0.9rem;
	border-bottom-style: none;
	font-family: Quicksand, sans-serif;
	color: #2e5f6c;
	background-color: #ffffff;
	font-weight: 500;
	text-transform: uppercase;
}

.left-nav-list-link:hover {
	background-color: #e4eef1;
	background-image: none;
	color: #1b365a;
}

.left-nav-list-link.w--current {
	background-color: #e4eef1;
	font-family: Quicksand, sans-serif;
	color: #2b4972;
}

.left-nav-list-link.section-title {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	background-color: #456a74;
	background-image: none;
	font-family: Quicksand, sans-serif;
	color: #fff;
	font-size: 1.25rem;
	line-height: 1em;
	font-weight: 400;
	text-transform: uppercase;
}

.left-nav-nested-list {
	margin-top: 0em;
	margin-bottom: 0em;
	padding-top: 0em;
	padding-bottom: 0em;
	border-top: 2px none #498fa3;
}

.left-nav-nested-list-item {
	margin-bottom: 0px;
	box-shadow: none;
}

.left-nav-nested-list-link {
	display: block;
	padding: 0.75em 1.5rem;
	border: 2px none transparent;
	font-family: Quicksand, sans-serif;
	color: #2e5f6c;
	background-color: #ffffff;
	font-size: 0.85em;
	line-height: 1.375em;
	font-weight: 500;
	text-transform: uppercase;
}

.left-nav-nested-list-link:hover {
	border: 2px none #48bdd7;
	background-color: #e4eef1;
	background-image: none;
	color: #11365a;
}

.left-nav-nested-list-link.w--current {
	border: 2px none #48bdd7;
	background-color: #e4eef1;
	background-image: none;
	color: #11365a;
}

.left-nav-nested-list-link.great-grandchild {
	padding-left: 2.5rem;
	font-size: 0.825em;
}

.right-sidebar-column .box,
.sidebar-section-wrap {
	position: relative;
	margin-bottom: 2.25rem;
	margin-left: 1rem;
	border-top: 1px none rgba(0, 0, 0, 0.15);
	font-size: 0.875rem;
	line-height: 1.35rem;
}

.sidebar-section-title {
	margin-top: 0em;
	margin-bottom: 0.75em;
}

.sidebar-list {
	margin-top: 0.5rem;
	margin-bottom: 0.75rem;
	padding-left: 0em;
}

.sidebar-list-item {
	margin-bottom: 0.75rem;
	padding-left: 0px;
}

.section-wrap.inside-content-section {
	background: #e5e5e5;
}

.secondary-nav-icon {
	width: 1.375em;
	height: 1.375em;
	margin-right: 0.5rem;
	float: left;
	opacity: 0.4;
}

.secondary-nav-text {
	float: left;
}

.secondary-nav-list-link {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 1.5rem;
	margin-left: 1.5rem;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom-style: none;
	color: hsla(0, 0%, 100%, 0.5);
	font-size: 0.875rem;
	text-transform: uppercase;
}

.secondary-nav-list-link:hover {
	color: #fff;
	text-transform: uppercase;
}

.slider-section {
	position: relative;
	background-color: #373a3d;
}

.slider {
	display: block;
	height: 800px;
	max-width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding-top: 222px;
	background-color: #fff;
}

.slider-arrow {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50px;
	height: 500px;
	margin-bottom: 49px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	opacity: 1;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	color: #fff;
	font-size: 2rem;
}

.slider-arrow:hover {
	opacity: 1;
}

.slider-indicator {
	display: block;
	height: 3rem;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	font-size: 0.5rem;
	line-height: 2.25rem;
}

.main-section {
	padding-top: 2rem;
	padding-bottom: 4rem;
	background-color: #09182e;
}

.slider-image {
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	display: block;
	width: 100%;
	height: 100%;
	margin-right: auto;
	margin-left: auto;
	position: relative;
	border-bottom: 50px solid rgba(46, 95, 108, 0.6);
}

.slider-image .slider-slide-image {
	width: calc(100% - 545px);
	height: 100%;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	float: left;
}

.inside-page-header-content-wrap {
	margin-bottom: 1.5rem;
	padding-bottom: 0.5rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.inside-page-header-section {
	position: relative;
	min-height: 120px;
	background-color: #373a3d;
}

.inside-content-beckground {
	position: relative;
	display: block;
	max-width: 1300px;
	margin-top: -100px;
	margin-right: auto;
	margin-left: auto;
	padding: 3rem 2rem 4rem;
	background-color: #fff;
	min-height: 500px;
}

.main-content-wrap.nested-content-wrap {
	margin-right: 0.5em;
}

.header-menu-inside-wrap {
	display: block;
	max-width: 1400px;
	margin-right: auto;
	margin-left: auto;
}

.intro_text_style,
.intro-text-style {
	margin-top: 0rem;
	margin-bottom: 0.75rem;
	font-size: 1.1875em;
	line-height: 1.5em;
}

.slider-arrow-icon {
	top: 450px;
	padding-top: 1px;
}

.wide-background-block {
	display: block;
	max-width: 1170px;
	margin-right: auto;
	margin-left: auto;
}

.wide-background-block.content-box-background {
	position: relative;
	z-index: 100;
	max-width: 1400px;
	margin-top: 0px;
	padding-top: 2rem;
	padding-bottom: 2rem;
	background-color: #fff;
	box-shadow: 0 10px 22px 0 rgba(0, 0, 0, 0.25);
}

.slider-caption-title {
	display: block;
	max-width: 1400px;
	margin: 0.5em auto;
	color: #fff;
	font-size: 1.4rem;
	font-weight: 400;
	letter-spacing: 0em;
}

.footer-social-icon-link {
	margin-right: 8px;
	margin-bottom: 8px;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	border: 2px solid transparent;
	background-color: #498fa3;
	opacity: 1;
}

.footer-social-icon-link:hover {
	border-style: solid;
	border-color: #48bdd7;
	background-color: transparent;
	opacity: 1;
}

.footer-social-icon-image {
	width: 28px;
	height: 28px;
	padding: 0px;
}

.left-nav-list-content-wrap {
	display: block;
	overflow: hidden;
	height: auto;
	margin-top: -1.5rem;
	margin-right: 0.5rem;
	padding-bottom: 1.5rem;
	border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.footer-link {
	font-family: Quicksand, sans-serif;
	color: #8ec8e4;
}

.footer-link:hover {
	color: #fff;
	font-family: Quicksand, sans-serif;
}

.link {
	border-bottom: 1px solid rgba(84, 159, 196, 0.35);
	color: #4b666d;
	font-weight: 500;
}

.link:hover {
	border-bottom-style: solid;
	border-bottom-color: #549fc4;
}

.link.w--current {
	color: #28313b;
	font-weight: 700;
}

.link.pagination-link {
	margin: 0.5em 0.25em;
	float: left;
	font-weight: 700;
	text-transform: uppercase;
}

.link.pagination-link.next {
	float: right;
}

.link.breadcrumb-link {
	color: #4b666d;
	text-decoration: none;
}

.link.breadcrumb-link.w--current {
	font-weight: 500;
}

.pagination-middle-wrap {
	display: inline-block;
	clear: both;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.pagination-middle-text {
	display: inline-block;
	margin-right: 0.5em;
	margin-left: 0.5em;
}

.pagination-middle-select {
	display: inline-block;
	width: auto;
	max-height: 2.25rem;
	margin-bottom: 0px;
	padding: 0px 0px 0px 2px;
}

.pagination-wrap {
	margin-bottom: 1.5em;
	padding-top: 0.75em;
	padding-bottom: 0.75em;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	font-size: 0.875em;
	line-height: 1.25em;
	text-align: center;
}

.md-doctor-form-reveal-wrap {
	display: none;
	overflow: hidden;
}

.divider {
	height: 1px;
	margin-top: 1.5em;
	margin-bottom: 1.5em;
	clear: both;
	background-color: rgba(0, 0, 0, 0.15);
}

.md-advanced-search-tooltip-popup {
	position: absolute;
	left: -1rem;
	bottom: 100%;
	display: none;
	width: 155px;
	margin-bottom: 0.5rem;
	padding: 0.75rem 1.125rem 1.125rem 0.875rem;
	border-radius: 3px;
	background-color: #28313b;
	box-shadow: 0 0 20px 5px rgba(40, 49, 59, 0.25);
	color: #fff;
	font-size: 0.8125em;
	line-height: 1.375em;
}

.md-patient-comments-reveal-more {
	overflow: hidden;
}

.shop-item-row-wrap-grid-view {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.table-style-embed.reports-main {
	margin-top: 1rem;
}

.loc-view-buttons-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.loc-view-button-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: 48%;
	padding-top: 0.5em;
	padding-right: 0.25em;
	padding-bottom: 0.5em;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-style: solid;
	border-width: 2px;
	border-color: rgba(0, 0, 0, 0.5);
	border-radius: 20px;
	opacity: 0.55;
	-webkit-transition: color 150ms ease, background-color 150ms ease, opacity 150ms ease, border-color 150ms ease, box-shadow 150ms ease, all 200ms ease;
	transition: color 150ms ease, background-color 150ms ease, opacity 150ms ease, border-color 150ms ease, box-shadow 150ms ease, all 200ms ease;
	color: #000;
	font-weight: 700;
}

.loc-view-button-wrap:hover {
	border-color: rgba(0, 0, 0, 0.25);
	opacity: 1;
}

.loc-view-button-wrap.w--current {
	border-color: #000;
	opacity: 0.7;
}

.loc-view-button-wrap.w--current:hover {
	opacity: 1;
}

.loc-miles-from-zip-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.loc-list-section-title {
	margin-top: 0em;
}

.loc-select-type-form-wrap {
	position: relative;
}

.loc-type-form {
	position: relative;
}

.loc-type-form-checkbox {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	margin-bottom: 0.75em;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	line-height: 1.375em;
}

.loc-type-form-checkbox.select-all-wrap {
	font-weight: 700;
}

.loc-type-form-checkbox.sidebar-checkbox {
	width: 100%;
	margin-bottom: 0.5em;
}

.loc-type-form-checkbox.sidebar-checkbox.select-all {
	margin-top: 0.75em;
	font-weight: 600;
}

.loc-type-form-checkbox-box {
	position: relative;
	width: 1.5em;
	height: 1.5em;
	margin-top: 0px;
	margin-right: 0.5em;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
}

.loc-type-form-checkbox-box.select-all-checkbox {
	width: 1.4125em;
	height: 1.4125em;
	margin-right: 0.5em;
}

.loc-type-form-checkbox-label {
	position: relative;
	float: none;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
}

.loc-type-form-checkbox-label.select-all-label {
	font-weight: 700;
	text-transform: uppercase;
}

.loc-type-form-checkboxes-wrapper {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0.75em;
	margin-bottom: 1.25em;
	padding-top: 1em;
	padding-bottom: 0.25em;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.loc-type-form-checkbox-image {
	position: relative;
	display: block;
	width: 1.5em;
	height: 1.5em;
	margin-right: 0.5em;
}

.loc-sidebar-form-reveal {
	overflow: hidden;
	height: auto;
}

.loc-sidebar-search-reveal-button {
	position: relative;
	display: none;
	margin-bottom: 0em;
	padding: 0.75em 0.5em 0.75em 0.25em;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 1px solid rgba(84, 159, 196, 0.25);
	font-size: 1.125em;
	line-height: 1.25em;
	font-weight: 600;
	cursor: pointer;
}

.loc-refine-search-button-image {
	width: 0.75em;
	height: 0.75em;
	margin-top: 3px;
}

.right-sidebar-refine-search-button-text {
	margin-top: 0em;
	margin-bottom: 0em;
	margin-left: 0.25em;
	font-size: 1.125em;
	line-height: 1.25em;
}

.loc-refine-search-checkbox-wrap {
	padding-top: 0.5em;
	border-top: 1px solid rgba(84, 159, 196, 0.25);
}

.loc-view-button-text {
	margin-left: 0.25em;
}

.loc-view-button-image {
	position: relative;
	display: block;
	width: 1.125rem;
	height: 1.375rem;
}

.pagination-view-by-button {
	width: 2.25rem;
	height: 2.25rem;
	margin-left: 0.5rem;
	border: 1px solid #000;
	opacity: 0.35;
}

.pagination-view-by-button:hover {
	opacity: 1;
}

.inside-page-content-wrap-no-sidebars {
	margin-right: 3%;
	margin-left: 3%;
}

.nav-drop-wrap {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	border-right: 1px none hsla(0, 0%, 50%, 0.25);
	margin-bottom: 0;
}

.nav-drop-toggle {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 0px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
	box-shadow: none;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: hsla(0, 0%, 65%, 0.5);
}

.nav-drop-toggle:hover {
	background-color: rgba(0, 0, 0, 0.1);
	box-shadow: none;
	color: hsla(0, 0%, 65%, 0.8);
}

.nav-drop-wrap.has-dropdown.open .nav-drop-toggle-link,
.nav-drop-toggle.w--open {
	box-shadow: 0 -3px 0 0 rgba(0, 0, 0, 0.5);
	color: #fff;
}

.nav-drop-wrap.has-dropdown .nav-drop-toggle-link::after {
	content: "\e603";
	margin-top: auto;
	margin-bottom: auto;
	color: #fff;
	font-size: 0.75em;
	line-height: 1;
	font-family: 'webflow-icons';
	text-transform: none;
	position: absolute;
	right: 6%;
	top: 41%;
}

.nav-drop-icon {
	margin-top: auto;
	margin-right: 6%;
	margin-bottom: auto;
	color: #fff;
	font-size: 0.75em;
	line-height: 1.25em;
}

.nav-drop-list {
	display: none;
}

.open .nav-drop-list {
	display: block;
	left: 0px;
	top: 100%;
	display: block;
	padding: 0.5em;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	background-color: #000;
	color: #fff;
	margin: 0;
	list-style-type: none;
	font-family: 'Crete Round', sans-serif;
	font-weight: 400;
}

.open .nav-drop-list a,
.nav-drop-list-link {
	display: block;
	padding: 0.5em 1.5em 0.5em 0.5rem;
	color: hsla(0, 0%, 100%, 0.75);
	font-size: 0.875rem;
}

.open .nav-drop-list a:hover,
.nav-drop-list-link:hover {
	display: block;
	color: #fff;
}

.open .nav-drop-list a.w--current,
.nav-drop-list-link.w--current {
	border-left-style: none;
	color: #48bdd7;
}

.nav-drop-toggle-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 1rem 0.25rem 1rem 0.25rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 5px solid transparent;
	font-family: Quicksand, sans-serif;
	color: #fff;
	font-size: 1rem;
	line-height: 1.375em;
	font-weight: 400;
	text-align: center;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.has-dropdown .nav-drop-toggle-link {
	padding-right: 1.5rem;
	pointer-events: none;
}

.nav-drop-wrap:hover .nav-drop-toggle-link {
	border-bottom: 5px solid #48bdd7;
	color: #fff;
}

.nav-drop-wrap:hover .nav-drop-toggle-link.active-item {
	border-bottom: 0 none;
}

.has-dropdown .nav-drop-toggle-link.w--current {
	border-bottom: 5px solid transparent;
}

.has-dropdown:hover .nav-drop-toggle-link.w--current {
	border-bottom: 5px solid #48bdd7;
	color: #fff;
}

.header-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 1400px;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.shop-item-row-wrap-list-view {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.header-content-right-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0.5rem;
	margin-bottom: 0rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
}

.header-search-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0px;
	margin-left: 0.25rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.header-search-form {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	background-color: rgba(46, 95, 108, 0.5);
}

.mobile-menu-styles-block {
	position: relative;
	overflow: hidden;
	background-color: #28313b;
}

.footer-social-icons {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-content: flex-start;
	-ms-flex-line-pack: start;
	align-content: flex-start;
}

.footer-contact-label {
	display: inline-block;
	min-width: 64px;
}

.ordered-list.alpha-order {
	list-style-type: lower-alpha;
}

.ordered-list.roman-order {
	list-style-type: lower-roman;
}

.main-content-image-example {
	display: none;
	width: 100%;
	height: 250px;
	margin-bottom: 1.5em;
	float: none;
	background-color: #e0e0e0;
}

.nav-drop-list-new {
	overflow: hidden;
}

.nav-drop-list-new.w--open {
	left: 0px;
	top: 100%;
	padding: 0.5em;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	background-color: #000;
	color: #fff;
}

.nav-drop-list-element {
	position: absolute;
	left: 0%;
	top: 100%;
	z-index: 3;
	display: none;
	overflow: hidden;
	width: 100%;
	min-width: 200px;
	margin: 0px;
	padding: 0.5rem;
	background-color: #000;
	box-shadow: 0 3px 6px -3px rgba(0, 0, 0, 0.25);
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	color: #fff;
}

.nav-drop-background {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 1;
	display: none;
	background-color: rgba(0, 0, 0, 0.25);
}

.footer-container {
	display: block;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 2rem;
	padding-left: 2rem;
}

.main-content-wrapper {
	display: block;
	width: 95%;
	margin-right: auto;
	margin-left: auto;
}

.div-block {
	display: none;
}

.main-content-container {
	display: block;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
}

.heading {
	font-size: 0.825rem;
}

.heading-2 {
	font-size: 0.95rem;
}

.body {
	font-family: 'Crete Round', sans-serif;
	font-weight: 400;
	line-height: 1.5rem;
}

.slider-2 {
	display: none;
	width: 25%;
	height: 380px;
	max-width: 450px;
	min-height: 380px;
	min-width: 300px;
	margin-top: auto;
	float: right;
	background-color: transparent;
	background-image: none;
}

.left-arrow {
	display: block;
}

.right-arrow {
	display: none;
}

.slide-nav {
	font-size: 0.65rem;
}

.slide-nav.heading {
	padding-top: 0.85rem;
	background-image: -webkit-linear-gradient(270deg, rgba(9, 24, 46, 0.85), rgba(9, 24, 46, 0.85));
	background-image: linear-gradient(180deg, rgba(9, 24, 46, 0.85), rgba(9, 24, 46, 0.85));
}

.slider-content-wrapper {
	display: block;
	max-width: 1400px;
	margin-top: 21px;
	margin-right: auto;
	margin-left: auto;
}

.mini-slider-wrapper {
	padding: 1.5rem;
	border-bottom: 3px solid #48bdd7;
	background-image: -webkit-linear-gradient(270deg, rgba(9, 24, 46, 0.84), rgba(9, 24, 46, 0.84));
	background-image: linear-gradient(180deg, rgba(9, 24, 46, 0.84), rgba(9, 24, 46, 0.84));
}

.mini-slider-heading {
	font-family: Quicksand, sans-serif;
	color: #fff;
	font-size: 1.25rem;
	font-weight: 400;
	text-transform: uppercase;
}

.mini-slider-text {
	margin-bottom: 1.5rem;
	color: #000;
	font-size: 1rem;
}

.mini-slider-text-wrapper {
	height: 242px;
	padding: 1rem 1.5rem 2.25rem;
	background-image: -webkit-linear-gradient(270deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), -webkit-linear-gradient(270deg, hsla(0, 0%, 100%, 0.9), rgba(20, 43, 76, 0.1));
	background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.65), hsla(0, 0%, 100%, 0.65)), linear-gradient(180deg, hsla(0, 0%, 100%, 0.9), rgba(20, 43, 76, 0.1));
}

.row-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: auto;
	margin-left: auto;
	padding-right: 2rem;
	padding-left: 2rem;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
}

.row-wrapper.two-column {
	display: none;
}

.small-cloumn {
	width: 50%;
	padding-bottom: 1rem;
	float: left;
	border-right: 5px solid #fff;
	background-image: -webkit-linear-gradient(270deg, rgba(9, 24, 46, 0.05), rgba(9, 24, 46, 0.05));
	background-image: linear-gradient(180deg, rgba(9, 24, 46, 0.05), rgba(9, 24, 46, 0.05));
}

.large-column {
	width: 50%;
	padding-bottom: 1rem;
	float: left;
	border-left: 5px solid #fff;
	background-image: -webkit-linear-gradient(270deg, rgba(9, 24, 46, 0.05), rgba(9, 24, 46, 0.05));
	background-image: linear-gradient(180deg, rgba(9, 24, 46, 0.05), rgba(9, 24, 46, 0.05));
}

.column-heading {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 60px;
	padding: 0.65rem 1.5rem 0.65rem 1.25rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 5px solid #498fa3;
	background-color: #142b4c;
	font-family: Quicksand, sans-serif;
	color: #fff;
	font-size: 1rem;
	line-height: 1.3em;
	text-transform: uppercase;
}

.unordered-list {
	margin-top: 1em;
	margin-bottom: 1em;
	padding-right: 1rem;
	padding-left: 1rem;
}

.text-block-homepage-paddings,
.text-block-homepage {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding-right: 1rem;
	padding-left: 1rem;
	font-family: 'Crete Round', sans-serif;
	font-weight: 400;
	max-height: 400px;
	overflow: auto;
}

.text-block-homepage-paddings ul,
.text-block-homepage-paddings p {
	font-family: inherit;
}

.text-block-homepage ul,
.text-block-homepage p {
	font-family: inherit;
	margin: 0;
	color: inherit;
	font-size: inherit;
	line-height: inherit;
}

.small-column {
	width: 50%;
	padding-bottom: 1rem;
	float: left;
	border-right: 3px solid #fff;
	border-left: 4px solid #fff;
	background-image: -webkit-linear-gradient(270deg, #f3f3f3, #f3f3f3);
	background-image: linear-gradient(180deg, #f3f3f3, #f3f3f3);
}

.date-text {
	display: inline;
	padding-right: 0.5rem;
}

.report-list-item {
	margin-bottom: 1rem;
	font-family: 'Crete Round', sans-serif;
	font-weight: 400;
}

.two-column-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
}

.form-2 {
	float: right;
}

.form-block {
	float: right;
}

.welcome-message {
	position: relative;
	z-index: 1;
	display: none;
	height: 260px;
	max-width: 1600px;
	margin-top: -260px;
	margin-right: auto;
	margin-left: auto;
	padding-top: 2rem;
	padding-bottom: 2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	background-image: -webkit-linear-gradient(270deg, #2e5f6c, #2e5f6c);
	background-image: linear-gradient(180deg, #2e5f6c, #2e5f6c);
}

.footer-contact-para {
	color: hsla(0, 0%, 100%, 0.65);
	font-family: Quicksand, sans-serif;
}

.photo-image-section {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: auto;
	height: 600px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-image: var(--rotunda-bg-image);
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
}

.content-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-right: 15%;
	margin-left: 15%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: -webkit-radial-gradient(circle farthest-corner at 50% 50%, hsla(0, 0%, 100%, 0.35), transparent), -webkit-linear-gradient(270deg, hsla(0, 0%, 100%, 0.2), hsla(0, 0%, 100%, 0.2));
	background-image: radial-gradient(circle farthest-corner at 50% 50%, hsla(0, 0%, 100%, 0.35), transparent), linear-gradient(180deg, hsla(0, 0%, 100%, 0.2), hsla(0, 0%, 100%, 0.2));
}

.portrait-image {
	display: block;
	margin-right: auto;
	margin-left: auto;
}

.our-mission-heading {
	padding: 0.85rem;
	color: #fff;
	font-size: 2rem;
	font-family: 'Crete Round', sans-serif;
	font-weight: 400;
}

.mission-text {
	width: 100%;
	margin: 0.35rem 15%;
	padding-right: 3rem;
	padding-left: 3rem;
	color: hsla(0, 0%, 100%, 0.65);
	text-align: center;
}

.mission-text p {
	margin: 0;
	color: inherit;
	font-size: inherit;
	line-height: inherit;
	font-family: 'Crete Round', sans-serif;
	font-weight: 400;
}

.blockquote {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1.75rem 1.5rem;
	border-left-color: #498fa3;
	background-color: #f2f2f2;
}

.color-banner {
	height: 175px;
	margin-top: 50px;
	background-color: #2e5f6c;
}

.mission-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 200px;
	padding-top: 2rem;
	padding-bottom: 2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 4px solid #498fa3;
	border-bottom: 4px solid #498fa3;
	background-image: -webkit-linear-gradient(270deg, #09182e, #09182e);
	background-image: linear-gradient(180deg, #09182e, #09182e);
}

.back-to-top-button {
	position: fixed;
	right: 0px;
	bottom: 0px;
	z-index: 1002;
	display: block;
	height: 70px;
	margin-bottom: 1rem;
	padding: 1.25rem 1rem;
	border-bottom-style: none;
	background-color: rgba(0, 0, 0, 0.75);
	background-image: url('/20180504170300/assets/images/top_arrow-01.svg');
	background-position: 50% 12px;
	background-size: 24px;
	background-repeat: no-repeat;
	font-family: Quicksand, sans-serif;
}

.back-to-top-button:hover {
	background-color: rgba(0, 0, 0, 0.75);
}

.back-to-top-button.w--current {
	right: 0px;
	bottom: 0px;
	z-index: 1000;
}

.back-to-top-text {
	padding-top: 1.1rem;
	font-family: Quicksand, sans-serif;
	color: #fff;
	font-size: 0.85rem;
	letter-spacing: 1px;
}

.quicklinks-arrow {
	width: 25px;
	margin-right: 0.5rem;
}

.quicklinks-arrow:hover {
	-webkit-transform: scale(1.05);
	-ms-transform: scale(1.05);
	transform: scale(1.05);
}

.text-block-homepage-paddings ul,
.text-block-homepage ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.text-block-homepage-paddings li,
.text-block-homepage li {
	display: flex;
}

.text-block-homepage-paddings li:before,
.text-block-homepage li:before {
	content: '';
	display: inline-block;
	height: 25px;
	width: 25px;
	background-image: url('/20180504170300/assets/images/next_pointing_right-01.svg');
	vertical-align: bottom;
	padding-right: 5px;
	background-repeat: no-repeat;
}

.text-block-homepage-paddings li div,
.text-block-homepage li div {
	width: calc(100% - 30px);
}

.text-block-homepage-paddings li a,
.text-block-homepage li a {
	width: calc(100% - 25px);
}

.arrow-link-block {
	float: left;
	border-bottom-style: none;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}

.arrow-link-block:hover {
	-webkit-transform: scale(1.15);
	-ms-transform: scale(1.15);
	transform: scale(1.15);
}

.quicklink-links {
	display: inline;
	width: 80%;
	float: left;
	font-family: 'Crete Round', sans-serif;
	font-weight: 400;
	color: #2e5f6c;
}

.text-block-homepage-paddings a,
.text-block-homepage a,
.report-title {
	color: #2e5f6c;
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
}

.text-block-homepage-paddings a.primary,
.text-block-homepage a.primary,
.text-block-homepage-paddings a.secondary,
.text-block-homepage a.secondary {
	color: #fff;
}

.text-block-homepage-paddings a:hover,
.text-block-homepage a:hover {
	color: #28313b;
	text-decoration: none;
}

.text-block-homepage-paddings a.primary:hover,
.text-block-homepage a.primary:hover,
.text-block-homepage-paddings a.secondary:hover,
.text-block-homepage a.secondary:hover {
	color: #fff;
}

.iowa-gov-link-text {
	font-family: 'Crete Round', sans-serif;
	font-size: 1.25rem;
	font-weight: 400;
}

.iowa-gov-link-text:hover {
	color: #e2cb80;
	font-family: 'Crete Round', sans-serif;
}

.report-a-fraud-link {
	padding-bottom: 0rem;
	border-bottom-style: none;
	background-color: #09182e;
	box-shadow: inset 0 0 10px 2px rgba(0, 0, 0, 0.3);
	font-size: 1.15rem;
}

.report-a-fraud-link:hover {
	background-color: #fff;
	box-shadow: none;
	color: #2e5f6c;
}

.iowa-gov-link {
	margin-bottom: 2.5rem;
	border-bottom-style: none;
	color: #77acbb;
}

.report-a-fraud-link-text {
	padding: 0.45rem 0.75rem;
	border-bottom-style: none;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	font-family: 'Crete Round', sans-serif;
	font-weight: 400;
	color: #e2cb80;
	font-size: 1.15rem;
}

.report-a-fraud-link-text:hover {
	color: #498fa3;
	font-family: 'Crete Round', sans-serif;
}

.arrow-container {
	width: 50px;
	height: 50px;
	padding: 1rem;
	background-color: #09182e;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	color: #498fa3;
}

.arrow-container:hover {
	background: #2e5f6c;
	color: #fff;
}

.news-text {
	padding-bottom: 1rem;
	font-family: 'Crete Round', sans-serif;
	font-weight: 400;
}

.news-topic-title {
	display: inline-block;
}

.caption-box {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 545px;
	height: 528px;
	padding: 3rem;
	float: right;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-image: -webkit-linear-gradient(270deg, hsla(0, 0%, 100%, 0.8), hsla(0, 0%, 100%, 0.8));
	background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.8), hsla(0, 0%, 100%, 0.8));
}

.slider-caption-title-heading {
	width: 100%;
	margin-bottom: 1rem;
	color: #142b4c;
	font-size: 2rem;
	line-height: 2.5rem;
	font-family: 'Crete Round', sans-serif;
	font-weight: 400;
}

.caption-text {
	width: 100%;
	margin-bottom: 0.65rem;
}

.caption-text p {
	font-family: 'Crete Round', sans-serif;
	font-weight: 400;
}

.slider-full-link {
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.w-slide .video-frame {
	width: 100%;
	height: 100%;
	background: url("/local/public/shared/assets/images/websites/play.png") center center no-repeat;
	cursor: pointer;
}

.iowa-gov-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.current-topics-heading-link {
	display: inline;
	width: 80%;
	float: left;
	font-family: 'Crete Round', sans-serif;
	font-weight: 400;
	color: #2e5f6c;
}

.filter-by-text {
	margin-bottom: 0.5rem;
	float: left;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	font-family: Quicksand, sans-serif;
	color: #498fa3;
	font-weight: 500;
	text-transform: uppercase;
}

.filters-form-block {
	position: relative;
	margin-bottom: 0px;
}

.filters-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.filter-type-wrapper {
	position: relative;
	width: 20%;
	margin-top: 0.5rem;
}

.filter-type-wrapper.search {
	width: 100%;
	margin-right: 0rem;
}

.filter-type-wrapper.entity {
	width: 19%;
	padding-right: 0.75rem;
}

.filter-type-wrapper.audit-category {
	width: 19%;
	padding-right: 0.75rem;
}

.filter-type-wrapper.release-date {
	width: 29%;
	padding-right: 0.75rem;
}

.filter-type-wrapper.fiscal-year {
	width: 29%;
	padding-right: 0.75rem;
}

.filter-type-wrapper.firm {
	width: 18%;
}

.filter-type-wrapper.city {
	width: 19%;
	padding-right: 0.75rem;
}

.filter-heading {
	min-width: 90px;
	margin-bottom: 0.5rem;
	display: inline-block;
	white-space: nowrap;
}

.filter-heading.filter-subheading {
	width: 5%;
	min-width: 12px;
	margin-right: 4px;
	margin-bottom: 0rem;
	margin-left: 4px;
	font-size: 0.8rem;
}

.opp-filter-dd-list {
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}

.filter-dd-toggle {
	overflow: hidden;
	height: 40px;
	margin-bottom: 0px;
	padding: 0 0 0 2px;
	border: 1px solid #ccc;
	background-color: #fff;
	background-image: -webkit-linear-gradient(270deg, #fff, #fff);
	background-image: linear-gradient(180deg, #fff, #fff);
	color: #444;
	font-size: 0.8rem;
	line-height: 1.6rem;
}

.filter-dd-toggle.fiscal-year-dd {
	width: 44%;
	padding-right: 12px;
}

.city-financlial-reports-button {
	padding: 0.65rem 3rem 0.65rem 1.5rem !important;
}

.date-picker-inner-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.filter-wrapper .hasDatepicker,
.date-picker-field {
	width: calc(100% - 25px);
	height: 40px;
	margin-right: 5px;
	margin-bottom: 0px;
	padding-right: 6px;
	padding-left: 6px;
	font-size: 0.8rem;
}

.filter-wrapper .ui-form-datepicker {
	width: 49%;
	height: 40px;
	font-size: 0.8rem;
	margin-bottom: 0px;
	padding-right: 0;
	padding-left: 0;
}

.filter-wrapper .hasDatepicker::-webkit-input-placeholder,
.date-picker-field::-webkit-input-placeholder {
	color: #444;
	font-size: 0.8rem;
}

.filter-wrapper .hasDatepicker:-ms-input-placeholder,
.date-picker-field:-ms-input-placeholder {
	color: #444;
	font-size: 0.8rem;
}

.filter-wrapper .hasDatepicker::placeholder,
.date-picker-field::placeholder {
	color: #444;
	font-size: 0.8rem;
}

.reset-filters-link {
	font-family: Quicksand, sans-serif;
	color: #27758a !important;
	font-size: 0.8rem;
	font-weight: 400 !important;
}

.reset-filters-link:hover {
	color: #28313b !important;
	text-decoration: none;
	border-bottom-color: rgba(73, 143, 163, 0.35) !important;
}

.keyword-main-search {
	width: 100%;
	padding-bottom: 1rem;
}

.keyword-search-field {
	width: 100%;
	height: 35px;
	margin-bottom: 0px;
	padding-left: 6px;
	border: 1px solid #ccc;
	font-size: 0.8rem;
}

.keyword-search-field::-webkit-input-placeholder {
	color: #000;
	font-size: 0.8rem;
}

.keyword-search-field:-ms-input-placeholder {
	color: #000;
	font-size: 0.8rem;
}

.keyword-search-field::placeholder {
	color: #000;
	font-size: 0.8rem;
}

.keyword-search-field.city-financials {
	display: none;
}

.filter-by-text-div {
	display: none;
	width: 100%;
}

.filter-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-bottom: 1rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
}

.filter-wrapper.financials {
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.button-form-div {
	width: 100%;
	padding-bottom: 1rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.results-div {
	padding-bottom: 1rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.pagination-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 2rem;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	justify-content: center;
}

.pagination-block-left {
	display: block;
	width: 18%;
	height: 25px;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom-style: none;
}

.pagination-block-middle {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 89%;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
}

.pagination-block-right {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 18%;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	border-bottom-style: none;
}

.prev-arrow {
	height: 25px;
	padding-right: 0.3rem;
	padding-left: 0rem;
	float: left;
	color: #6d6d6d;
	font-size: 1.5rem;
	line-height: 1.2rem;
}

.previous {
	height: 25px;
	margin-bottom: 0rem;
	float: left;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	font-family: Quicksand, sans-serif;
	color: #6d6d6d;
	font-weight: 500;
	text-transform: uppercase;
}

.previous:hover {
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	color: grey;
}

.next {
	height: 25px;
	margin-bottom: 0rem;
	float: left;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	font-family: Quicksand, sans-serif;
	color: #27758a;
	font-weight: 500;
	text-transform: uppercase;
}

.next:hover {
	border-bottom: 1px solid rgba(73, 143, 163, 0.5);
	color: #2e5f6c;
}

.page-picker-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 0.25rem;
	margin-left: 0.25rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.page-picker-wrap._1 {
	margin-left: 0rem;
}

.page-picker-wrap._3 {
	margin-right: 0rem;
}

.page-label {
	margin-right: 0.5rem;
	margin-bottom: 0rem;
	margin-left: 0.5rem;
	font-size: 0.85rem;
	font-weight: 400;
}

.pagination-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.page-dropdown {
	width: 50px;
	height: 30px;
	margin-bottom: 0px;
	padding: 0rem 4px 0px;
	background-color: #fff;
	background-image: -webkit-linear-gradient(270deg, #fff, #fff);
	background-image: linear-gradient(180deg, #fff, #fff);
	font-size: 0.9rem;
}

.page-dropdown.results {
	width: 47px;
}

.next-arrow {
	height: 25px;
	padding-right: 0rem;
	padding-left: 0.3rem;
	float: left;
	font-size: 1.5rem;
	line-height: 1.2rem;
}

.reports-table-icon {
	width: 20px;
	height: 20px;
	margin-right: 0.5rem;
}

.detail-item-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-bottom: 0.75rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.details-title {
	padding-right: 0.5rem;
	font-family: 'Crete Round', sans-serif;
	font-weight: 400;
	font-size: 1.25rem;
}

.pdf-info-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom-style: none;
}

.pdf-icon {
	height: 22px;
	margin-right: 0.5rem;
}

.search-heading {
	margin-top: 0rem;
	float: left;
}

table.styled.reports.with-experts tbody tr {
	border-bottom: 0 none;
}

table.styled tr.expert-table-row td {
	padding: 0;
}

table.styled tr.expert-table-row td b {
	display: none;
}

table.styled tr.expert-table-row td span {
	max-width: 100%;
}

@media (max-width: 1100px) {
	.header-content-right-wrap {
		flex-direction: column;
	}

	.iowa-gov-link {
		margin-bottom: 1rem;
	}

	.header-content-right-wrap .header-content-right-row {
		margin-top: 0;
		margin-bottom: 1rem;
	}

	.header-content-right-wrap .report-a-fraud-button {
		margin-bottom: 1rem;
		text-align: right;
	}
}

@media (max-width: 39.9375em) {
	table.styled tr.expert-table-row td span .pdf-text-block {
		padding: 0 12px;
	}
}

.pdf-excerpt-div {
	display: block;
	margin-right: auto;
	margin-left: auto;
	padding: 0.75rem 0.5rem;
	border-top: 2px solid rgba(73, 143, 163, 0.2);
	border-bottom: 2px solid rgba(73, 143, 163, 0.5);
}

.pdf-excerpt-div.colored {
	border-bottom: 2px solid rgba(73, 143, 163, 0.5);
	background-color: #f2f2f2;
}

.pdf-excerpt-div.colored.financial {
	display: none;
}

.pdf-excerpt-div.transparent {
	display: none;
	background-color: transparent;
}

.pdf-excerpt-div.transparent.financial {
	display: none;
}

.pdf-excerpt-div.financial {
	display: none;
}

.pdf-text-block {
	display: block;
	margin-right: auto;
	margin-left: auto;
}

.fiscal-year-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0.5rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	line-height: 1.4em;
}

.date-range-field-wrap {
	margin-bottom: 0px;
	margin-left: 0.5rem;
}

.checkbox {
	width: 15px;
	height: 15px;
	background-image: -webkit-linear-gradient(270deg, #421111, #421111);
	background-image: linear-gradient(180deg, #421111, #421111);
}

.fiscal-year-filter-heading {
	min-width: 90px;
	margin-bottom: 0rem;
}

.left-sidebar-custom a,
.inside-row-nested a,
.text-link {
	color: #2e5f6c;
	font-weight: 500;
	cursor: pointer;
}

.left-sidebar-custom a:hover,
.inside-row-nested a:hover {
	color: #28313b;
	font-weight: 500;
	border-bottom-color: #549fc4;
}

html.w-mod-js *[data-ix="md-patient-comments-reveal-on-load"] {
	height: 0px;
}

html.w-mod-js *[data-ix="for-two-part-button-list"] {
	-webkit-transform: translate(100%, 0px);
	-ms-transform: translate(100%, 0px);
	transform: translate(100%, 0px);
}

html.w-mod-js *[data-ix="display-none-on-page-load"] {
	display: none;
}

html.w-mod-js *[data-ix="for-grid-view-button"] {
	opacity: 0.7500000000000006;
}

html.w-mod-js *[data-ix="preloader-wrap"] {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

html.w-mod-js *[data-ix="hide-mobile-header-on-load"] {
	height: 0px;
}

html.w-mod-js *[data-ix="new-nav-drop-list"] {
	height: 0px;
}

html.w-mod-js *[data-ix="slider-upward-shift"] {
	opacity: 1;
}

.hidden-main {
	display: none;
}

.hidden-mobile {
	display: inline-block;
}

@media (max-width: 991px) {
	h1 {
		font-size: 2rem;
	}

	h2 {
		font-size: 1.75rem;
	}

	.hidden-main {
		display: inline-block;
	}

	.hidden-mobile {
		display: none;
	}

	.button.header-search-button {
		width: 7.5rem;
		min-height: 4rem;
		background-image: url('/20180504170300/assets/images/keyboard-right-arrow-button-1.svg');
		background-size: 20px 20px;
		font-size: 1.125rem;
	}

	.button.slider-button {
		display: block;
		width: 140px;
		margin-right: auto;
		margin-left: auto;
		padding-right: 1em;
		padding-left: 1em;
	}

	.container {
		max-width: 100%;
		padding-right: 20px;
		padding-left: 20px;
	}

	.container.inside-right {
		padding-right: 0;
		padding-left: 0;
	}

	.inside-right.w-container .w-row {
		margin-left: 0;
		margin-right: 0;
	}

	.nav-section {
		height: 155px;
	}

	.nav-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		max-width: 100%;
		margin-right: auto;
		margin-left: auto;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.nav-link {
		z-index: 140;
		display: block;
		width: 100%;
		margin-right: 0px;
		margin-bottom: 0px;
		padding: 1em 1rem 1em 2rem;
		border-bottom: 1px solid rgba(9, 24, 46, 0.5);
		box-shadow: none;
		line-height: 1.375rem;
		text-align: left;
	}

	.nav-link:hover {
		border-bottom: 1px solid transparent;
		background-color: #2e5f6c;
		box-shadow: none;
	}

	.nav-link.w--current {
		margin-bottom: 0px;
		padding-left: 2rem;
		border-bottom: 1px solid rgba(9, 24, 46, 0.5);
		border-top-width: 3px;
		background-color: transparent;
		box-shadow: inset 5px 0 0 0 #48bdd7;
		color: #fff;
	}

	.nav-link.w--current:hover {
		background: transparent;
	}

	.nav-link.two-part-mobile {
		width: 80%;
		float: left;
		text-transform: uppercase;
	}

	.nav-link.two-part-mobile:hover {
		border-bottom-style: solid;
		border-bottom-color: transparent;
		background-color: #2e5f6c;
		box-shadow: none;
	}

	.nav-link.mobile-list-back {
		background-color: rgba(0, 0, 0, 0.15);
		color: hsla(0, 0%, 100%, 0.55);
		cursor: pointer;
	}

	.nav-link.no-arrow {
		text-transform: uppercase;
	}

	.nav-link.no-arrow:hover {
		box-shadow: none;
	}

	.nav-link.no-arrow.last {
		border-bottom-width: 0px;
	}

	.nav-link.no-arrow.extra {
		border-bottom-width: 0px;
	}

	.nav-link.no-arrow.extra {
		border-bottom-width: 0px;
	}

	.nav-menu-wrap {
		display: block;
		margin-top: 1rem;
		padding-bottom: 100vh;
		background-color: transparent;
		background-image: -webkit-linear-gradient(270deg, rgba(46, 95, 108, 0.5), rgba(46, 95, 108, 0.5)), -webkit-linear-gradient(270deg, #09182e, #09182e);
		background-image: linear-gradient(180deg, rgba(46, 95, 108, 0.5), rgba(46, 95, 108, 0.5)), linear-gradient(180deg, #09182e, #09182e);
		text-align: left;
	}

	.logo {
		margin-left: 0.75em;
		font-size: 1.375rem;
	}

	.logo.w--current {
		margin-top: 0.75rem;
		margin-bottom: 1rem;
		margin-left: 0rem;
	}

	.row.footer-signoff-row {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.logo-image {
		width: 4rem;
		height: 4rem;
	}

	.menu-button {
		height: 3.5rem;
		padding: 1px 1.75rem 0px 1.5rem;
		float: left;
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-ms-flex-order: -1;
		order: -1;
		background-color: transparent;
		box-shadow: none;
		-webkit-transition: all 500ms ease;
		transition: all 500ms ease;
		color: rgba(0, 0, 0, 0.4);
		font-size: 1em;
		line-height: 3.5rem;
		font-weight: 600;
		text-align: center;
		text-transform: uppercase;
	}

	.menu-button:hover {
		background-image: -webkit-linear-gradient(270deg, rgba(46, 95, 108, 0.5), rgba(46, 95, 108, 0.5));
		background-image: linear-gradient(180deg, rgba(46, 95, 108, 0.5), rgba(46, 95, 108, 0.5));
	}

	.menu-button.w--open {
		background: transparent;
	}

	.footer-signoff-section {
		padding-top: 1rem;
		padding-bottom: 2rem;
		text-align: center;
	}

	.copyright-text {
		margin-bottom: 1rem;
		margin-left: 0.75rem;
	}

	.footer-signoff-grip {
		margin-top: 1rem;
		float: none;
	}

	.footer-column-wrap {
		max-width: 57%;
	}

	.footer-column-wrap._1 {
		width: 33.33%;
	}

	.footer-column-wrap._2 {
		width: 33.33%;
		max-width: 50%;
	}

	.footer-column-wrap._3 {
		width: 33.33%;
		max-width: 50%;
	}

	.header-content-right-wrap {
		margin-right: 0em;
		padding-bottom: 0rem;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		align-self: center;
	}

	.header-section {
		height: 100px;
	}

	.two-part-button-wrap {
		position: static;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		max-width: none;
		float: none;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-top: 1px none rgba(73, 143, 163, 0.35);
	}

	.two-part-button-wrap.link-after-home {
		border-top-style: none;
	}

	.header-menu-wrap {
		margin-top: 4px;
	}

	.form-field.right-sidebar-field {
		margin-bottom: 1.5em;
	}

	.form-field.right-sidebar-field.miles-from {
		width: 100%;
		margin-bottom: 0.25em;
	}

	.form-field.right-sidebar-field.zip-code {
		width: 100%;
	}

	.form-field.header-search-field {
		width: 100%;
		height: 4rem;
		padding-left: 1.5em;
		-webkit-box-flex: 100%;
		-webkit-flex: 100%;
		-ms-flex: 100%;
		flex: 100%;
		background-color: rgba(46, 95, 108, 0.85);
		font-size: 0.9375rem;
		line-height: 1.25em;
		font-family: 'Crete Round', sans-serif;
		font-weight: 400;
	}

	.form-field.header-search-field:focus {
		width: 100%;
		background-color: #2e5f6c;
	}

	.secondary-nav-list {
		margin-top: 0rem;
		margin-bottom: 0em;
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
	}

	.secondary-nav-list-item {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-top: 0rem;
		padding-bottom: 0rem;
		-webkit-align-self: stretch;
		-ms-flex-item-align: stretch;
		align-self: stretch;
		border-left: 1px none hsla(0, 0%, 100%, 0.15);
	}

	.main-content-column {
		padding-right: 0px;
		padding-left: 0px;
	}

	.right-sidebar-column {
		margin-top: 1.5rem;
		padding-top: 1.5rem;
		border-top: 3px solid #48bdd7;
	}

	.right-sidebar-column.right-sidebar {
		padding-right: 0px;
		padding-left: 0px;
	}

	.left-nav-list {
		margin-bottom: 1.5em;
	}

	.right-sidebar-column .box,
	.sidebar-section-wrap {
		margin-left: 0em;
	}

	.secondary-nav-icon {
		position: relative;
		z-index: 2;
		width: 1.5em;
		height: 1.5em;
		margin: 0.25em;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		opacity: 1;
		color: #fff;
	}

	.secondary-nav-text {
		margin-right: 0.25em;
		margin-left: 0.25em;
		font-family: Quicksand, sans-serif;
	}

	.secondary-nav-list-link {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 7.5rem;
		margin-right: 0rem;
		margin-left: 0rem;
		padding-right: 1rem;
		padding-left: 1rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		color: hsla(0, 0%, 100%, 0.75);
		font-size: 0.75rem;
		line-height: 1.25em;
		font-weight: 600;
		cursor: pointer;
	}

	.secondary-nav-list-link:hover {
		background: rgba(73, 143, 163, 0.5);
	}

	.slider {
		height: 660px;
		padding-top: 160px;
	}

	.slider-mask {
		background-image: none;
	}

	.slider-arrow {
		top: 75px;
		height: 300px;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
		font-size: 1.75rem;
	}

	.slider-indicator {
		height: 3.05rem;
		background-image: -webkit-linear-gradient(270deg, rgba(46, 95, 108, 0.55), rgba(46, 95, 108, 0.55));
		background-image: linear-gradient(180deg, rgba(46, 95, 108, 0.55), rgba(46, 95, 108, 0.55));
	}

	.slider-slide {
		height: 500px;
	}

	.slider-image {
		border-bottom-style: none;
	}

	.inside-page-header-content-wrap {
		margin-bottom: 1rem;
	}

	.inside-page-header-content-wrap.right-sidebar {
		margin-left: -10px;
	}

	.inside-page-header-section {
		min-height: 60px;
	}

	.inside-content-beckground {
		max-width: 100%;
		padding: 1.5rem 1.5rem 2rem;
	}

	.main-content-wrap.nested-content-wrap {
		margin-right: 0em;
	}

	.menu-button-icon {
		float: left;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		align-self: center;
		color: #fff;
		font-size: 2rem;
		line-height: 3.5rem;
	}

	.menu-button-text {
		margin-left: 0.125rem;
		float: left;
		font-family: Quicksand, sans-serif;
		color: hsla(0, 0%, 100%, 0.75);
		font-size: 0.75rem;
		line-height: 3.5rem;
		letter-spacing: 0.02em;
	}

	.header-menu-inside-wrap {
		max-width: 100%;
	}

	.intro_text_style,
	.intro-text-style {
		margin-top: 0rem;
	}

	.slider-arrow-icon {
		top: 250px;
	}

	.slider-caption-title {
		margin-bottom: 1em;
		background-image: -webkit-linear-gradient(270deg, #2e5f6c, #2e5f6c);
		background-image: linear-gradient(180deg, #2e5f6c, #2e5f6c);
		font-size: 1.25em;
	}

	.footer-social-icon-link {
		margin-right: 15px;
		margin-bottom: 15px;
	}

	.footer-social-icon-image {
		width: 32px;
		height: 32px;
	}

	.left-nav-list-content-wrap {
		overflow: hidden;
		height: 0px;
	}

	.md-doctor-form-reveal-wrap {
		display: none;
	}

	.loc-miles-from-zip-wrap {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.loc-type-form-checkbox {
		width: 33.33%;
	}

	.loc-type-form-checkbox.sidebar-checkbox {
		width: 33.33%;
		margin-bottom: 0.75em;
	}

	.loc-type-form-checkbox.sidebar-checkbox.select-all {
		width: 100%;
		margin-top: 0.75em;
	}

	.loc-type-form-checkboxes-wrapper {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.loc-sidebar-form-reveal {
		height: 0px;
	}

	.loc-sidebar-search-reveal-button {
		position: relative;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.loc-refine-search-checkbox-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-top: 0.75em;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.inside-page-content-wrap-no-sidebars {
		margin-right: 0%;
		margin-left: 0%;
	}

	.nav-drop-wrap {
		width: 100%;
	}

	.nav-drop-toggle {
		display: block;
	}

	.nav-drop-toggle-link {
		display: block;
		padding-left: 1rem;
		text-align: left;
	}

	.two-part-button-toggle {
		width: 20%;
		margin-bottom: 0px;
		padding-top: 1em;
		padding-bottom: 1em;
		float: right;
		background-color: rgba(0, 0, 0, 0.15);
		background-image: url('/20180504170300/assets/images/icon_arrow2_right_wht_50.svg');
		background-position: 50% 50%;
		background-size: 1em 1em;
		background-repeat: no-repeat;
		box-shadow: none;
		color: transparent;
		font-size: 0.75rem;
		line-height: 1.875rem;
		text-align: center;
		cursor: pointer;
	}

	.two-part-button-toggle:hover {
		background: rgba(0, 0, 0, 0.25);
	}

	.two-part-button-list {
		position: absolute;
		left: 0px;
		top: 0px;
		z-index: 150;
		display: none;
		width: 100%;
		margin-left: 100%;
		padding-bottom: 100vh;
		background-color: #28313b;
	}

	.two-part-drop-list-back-arrow {
		display: inline-block;
		min-width: 0.75em;
		margin-right: 0.25em;
		background-image: url('/20180504170300/assets/images/icon_arrow2_left_wht_50.svg');
		background-position: 0px 55%;
		background-size: 12px 12px;
		background-repeat: no-repeat;
		color: transparent;
		text-align: center;
	}

	.header-container {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.header-search-wrap {
		margin-left: 0rem;
		padding: 0rem;
	}

	.header-search-form {
		width: 100%;
	}

	.header-mobile-search-reveal {
		overflow: hidden;
		height: 0px;
		opacity: 1;
	}

	.footer-social-icons {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.mobile-search-close-icon {
		position: relative;
		z-index: 2;
		display: none;
		width: 1.5em;
		height: 1.5em;
		margin: 0.25em;
		padding: 1px;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.mobile-search-active-bknd {
		position: absolute;
		left: 0px;
		top: 0px;
		right: 0px;
		bottom: 0px;
		z-index: 1;
		display: none;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.1);
	}

	.footer-container {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.main-content-wrapper {
		width: 100%;
	}

	.main-content-container {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.slider-content-wrapper {
		margin-top: 0px;
	}

	.row-wrapper {
		display: block;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.small-cloumn {
		width: 100%;
		border-right-style: none;
	}

	.large-column {
		width: 100%;
		border-right: 1px none #000;
		border-left-style: none;
	}

	.small-column {
		width: 50%;
		margin-bottom: 1rem;
		border-right-style: solid;
		border-right-width: 5px;
		border-left-style: solid;
		border-left-width: 5px;
	}

	.two-column-wrapper {
		width: 100%;
	}

	.welcome-message {
		position: relative;
		bottom: 15px;
		z-index: 101;
		height: auto;
		margin-top: 0px;
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
		background-color: #2e5f6c;
	}

	.photo-image-section {
		height: 400px;
		background-image: var(--rotunda-bg-image);
		background-position: 50% 50%;
		background-size: cover;
		background-repeat: no-repeat;
		background-attachment: fixed;
	}

	.content-container {
		margin-right: 1.5rem;
		margin-left: 1.5rem;
		background-image: -webkit-linear-gradient(270deg, hsla(0, 0%, 100%, 0.18), hsla(0, 0%, 100%, 0.18));
		background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.18), hsla(0, 0%, 100%, 0.18));
	}

	.color-banner {
		height: 150px;
	}

	.mission-wrapper {
		height: auto;
	}

	.search-icon-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.iowa-gov-link-text {
		font-size: 1.15rem;
	}

	.iowa-gov-link {
		margin-bottom: 0.5rem;
	}

	.report-a-fraud-link-text {
		padding: 0.4rem 0.7rem;
		font-size: 1.05rem;
	}

	.caption-box {
		width: 325px;
		height: 451px;
	}

	.slider-image .slider-slide-image {
		width: calc(100% - 325px);
	}

	.slider-caption-title-heading {
		margin-bottom: 0.5rem;
		font-size: 1.65rem;
		line-height: 2.05rem;
	}

	.caption-text {
		margin-bottom: 0.35rem;
	}

	.filter-wrapper {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.filter-type-wrapper.entity {
		width: 33.5%;
	}

	.filter-type-wrapper.audit-category {
		width: 33.5%;
	}

	.filter-type-wrapper.release-date {
		width: 33%;
		padding-right: 0rem;
	}

	.filter-type-wrapper.fiscal-year {
		width: 33.5%;
	}

	.filter-type-wrapper.firm {
		width: 33.5%;
		padding-right: 0.75rem;
	}

	.filter-type-wrapper.city {
		width: 50%;
	}

	.filter-type-wrapper.fiscal-year {
		width: 50%;
	}

	.filter-dd-toggle {
		width: 100%;
	}

	.filter-wrapper {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.pdf-text-block {
		width: 100%;
		max-width: 700px;
	}
}

@media (max-width: 767px) {
	h1 {
		font-size: 1.6875rem;
		line-height: 1.3125em;
	}

	h2 {
		font-size: 1.5rem;
		line-height: 1.4375em;
	}

	h3 {
		font-size: 1.3125rem;
		line-height: 1.5em;
	}

	h4 {
		font-size: 1.125rem;
		line-height: 1.5em;
	}

	.button.header-search-button {
		min-height: 3.25rem;
	}

	.button.slider-button {
		width: 130px;
		font-size: 0.85rem;
	}

	.button.learn-more {
		padding-left: 1.25rem;
	}

	.container {
		padding-right: 10px;
		padding-left: 10px;
	}

	.container.inside-right {
		padding-right: 0px;
		padding-left: 0px;
	}

	.nav-section {
		height: 140px;
	}

	.nav-container {
		height: 3.5rem;
	}

	.logo {
		padding-left: 0px;
	}

	.row.footer-signoff-row {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.row.footer-row {
		display: block;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.logo-text {
		font-size: 1.15rem;
	}

	.logo-image {
		width: 3rem;
		height: 3rem;
		margin-right: 0.75rem;
	}

	.menu-button {
		height: 3.5rem;
	}

	.footer-column-wrap {
		display: block;
		width: 50%;
		max-width: 100%;
		-webkit-flex-basis: 50%;
		-ms-flex-preferred-size: 50%;
		flex-basis: 50%;
	}

	.footer-column-wrap._1 {
		width: 50%;
	}

	.footer-column-wrap._2 {
		width: 50%;
	}

	.footer-column-wrap._3 {
		width: 50%;
	}

	.header-section {
		height: 85px;
	}

	.form-field.header-search-field {
		height: 3.25rem;
	}

	.secondary-nav-list {
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
	}

	.secondary-nav-list-item {
		max-width: 100%;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-box-flex: 1;
		-webkit-flex: auto;
		-ms-flex: auto;
		flex: auto;
	}

	.right-sidebar-column {
		margin-top: 1.25rem;
		padding-top: 1.25rem;
	}

	.right-sidebar-column .box,
	.sidebar-section-wrap {
		margin-left: 0rem;
	}

	.secondary-nav-icon {
		margin-top: 0.25em;
		margin-bottom: 0.25em;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.secondary-nav-list-link {
		padding-right: 2em;
		padding-left: 1em;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-align-content: center;
		-ms-flex-line-pack: center;
		align-content: center;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		text-align: center;
	}

	.slider {
		height: auto;
		padding-top: 140px;
	}

	.slider-mask {
		margin-top: 0px;
		background-image: none;
	}

	.slider-arrow {
		bottom: auto;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 415px;
	}

	.slider-indicator {
		display: block;
		background-image: -webkit-linear-gradient(270deg, #2e5f6c, #2e5f6c);
		background-image: linear-gradient(180deg, #2e5f6c, #2e5f6c);
		font-size: 0.5rem;
	}

	.slider-slide {
		height: auto;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		-webkit-box-align: flex-end;
		-webkit-align-items: flex-end;
		-ms-flex-align: flex-end;
		align-items: flex-end;
	}

	.slider-image {
		position: relative;
		height: auto;
	}

	.slider-image .slider-slide-image {
		background-size: 180%;
	}

	.inside-page-header-content-wrap {
		padding-right: 0px;
		padding-left: 0px;
	}

	.inside-page-header-content-wrap.right-sidebar {
		margin-left: 0px;
	}

	.inside-page-header-section {
		min-height: 50px;
	}

	.inside-content-beckground {
		padding-right: 1.25rem;
		padding-bottom: 1.5rem;
		padding-left: 1.25rem;
	}

	.main-content-wrap.no-sidebars {
		margin-right: 0px;
		margin-left: 0px;
	}

	.intro_text_style,
	.intro-text-style {
		font-size: 1.125em;
	}

	.slider-arrow-icon {
		top: 365px;
	}

	.wide-background-block.content-box-background {
		margin-top: 0px;
		padding-top: 1.5rem;
		padding-bottom: 2rem;
	}

	.slider-caption-title {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
		background-image: none;
		font-size: 1.15em;
	}

	.pagination-middle-wrap {
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.loc-type-form-checkbox {
		width: 50%;
	}

	.loc-type-form-checkbox.sidebar-checkbox {
		width: 50%;
	}

	.header-content-right-row {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-align-content: space-around;
		-ms-flex-line-pack: distribute;
		align-content: space-around;
	}

	.main-content-image-example {
		margin-left: 0em;
		float: none;
	}

	.footer-container {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.main-content-container {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.left-arrow {
		position: absolute;
		height: 100%;
	}

	.slide-nav.heading {
		position: relative;
		bottom: 20px;
		z-index: 1;
		padding-top: 14px;
		background-image: -webkit-linear-gradient(270deg, #09182e, #09182e);
		background-image: linear-gradient(180deg, #09182e, #09182e);
	}

	.slider-content-wrapper {
		height: 350px;
	}

	.mini-slider-text-wrapper {
		height: 205px;
		background-image: -webkit-linear-gradient(270deg, #fff, #fff);
		background-image: linear-gradient(180deg, #fff, #fff);
	}

	.row-wrapper {
		height: 100%;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.small-column {
		width: 100%;
		height: 100%;
		border-right-style: none;
		border-left-style: none;
	}

	.two-column-wrapper {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.welcome-message {
		position: static;
		height: auto;
		padding-top: 2.5rem;
		background-color: #2e5f6c;
	}

	.photo-image-section {
		height: 350px;
	}

	.content-container {
		margin-right: 1.25rem;
		margin-left: 1.25rem;
	}

	.portrait-image {
		width: 140px;
	}

	.our-mission-heading {
		font-size: 1.9rem;
	}

	.mission-text {
		width: 90%;
		margin-right: 10%;
		margin-left: 10%;
		padding-right: 1rem;
		padding-left: 1rem;
		font-size: 0.95rem;
	}

	.color-banner {
		height: 145px;
		margin-top: 75px;
	}

	.search-icon-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.iowa-gov-link-text {
		font-size: 1rem;
	}

	.iowa-gov-link {
		margin-bottom: 0.25rem;
	}

	.report-a-fraud-link-text {
		padding: 0.3rem 0.55rem;
		font-size: 0.8rem;
		line-height: 1rem;
	}

	.caption-box {
		width: 100%;
		height: 251px;
		padding: 1.25rem;
		background-image: -webkit-linear-gradient(270deg, #fff, #fff);
		background-image: linear-gradient(180deg, #fff, #fff);
		margin-bottom: 49px;
	}

	.slider-image .slider-slide-image {
		width: 100%;
		float: none;
		height: 350px;
	}

	.slider-caption-title-heading {
		font-size: 1.5rem;
		line-height: 1.9rem;
	}

	.filter-type-wrapper {
		width: 50%;
	}

	.filter-type-wrapper.search {
		width: 100%;
	}

	.filter-type-wrapper.entity {
		width: 50%;
		margin-right: 0rem;
		padding-right: 0.25rem;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.filter-type-wrapper.audit-category {
		width: 50%;
		margin-right: 0rem;
		padding-right: 0rem;
		padding-left: 0.25rem;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.filter-type-wrapper.release-date {
		width: 50%;
		margin-right: 0rem;
		padding-right: 0.25rem;
	}

	.filter-type-wrapper.fiscal-year {
		width: 50%;
		margin-right: 0rem;
		padding-right: 0rem;
		padding-left: 0.25rem;
	}

	.filter-type-wrapper.firm {
		width: 100%;
		padding-right: 0rem;
	}

	.filter-type-wrapper.city {
		width: 50%;
		margin-right: 0rem;
		padding-right: 0.25rem;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.filter-type-wrapper.fiscal-year {
		width: 50%;
		margin-right: 0rem;
		padding-right: 0.25rem;
	}

	.filter-dd-toggle {
		width: 100%;
		font-size: 0.9rem;
	}

	.filter-wrapper .hasDatepicker,
	.date-picker-field {
		padding-left: 4px;
		font-size: 0.9rem;
	}

	.filter-wrapper .ui-form-datepicker {
		width: 50%;
		padding-left: 0;
		font-size: 0.9rem;
	}

	.keyword-search-field {
		height: 40px;
		font-size: 0.9rem;
	}

	.filter-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
	}

	.pagination-form-block {
		margin-bottom: 0px;
	}

	.pagination-block-left {
		width: 20%;
	}

	.pagination-block-middle {
		width: 60%;
	}

	.pagination-block-right {
		width: 20%;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.prev-arrow {
		padding-right: 0.2rem;
	}

	.previous {
		font-size: 0.85rem;
	}

	.next {
		font-size: 0.85rem;
	}

	.page-picker-wrap._1 {
		width: 100%;
		padding-bottom: 0.5rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.page-picker-wrap._3 {
		width: 100%;
		padding-top: 0.5rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.page-picker-wrap._2 {
		width: 100%;
		margin-right: 0rem;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.page-label {
		margin-right: 0.3rem;
		margin-left: 0.3rem;
		font-size: 0.8rem;
	}

	.pagination-form {
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.page-dropdown {
		width: 50px;
		padding-right: 4px;
		padding-left: 4px;
		font-size: 0.8rem;
	}

	.page-dropdown.results {
		width: 44px;
		padding-right: 4px;
		padding-left: 4px;
		font-size: 0.8rem;
	}

	.next-arrow {
		padding-left: 0.2rem;
	}

	.pdf-excerpt-div {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.pdf-text-block {
		width: 100%;
	}
}

@media (max-width: 479px) {
	body {
		font-size: 0.9375rem;
		line-height: 1.5em;
	}

	h1 {
		font-size: 1.625rem;
	}

	h2 {
		font-size: 1.4375rem;
		line-height: 1.5em;
	}

	.button {
		padding-right: 2.75rem;
		padding-left: 1.25rem;
	}

	.button.header-search-button {
		width: 5rem;
	}

	.button.slider-button {
		width: 120px;
		font-size: 0.75rem;
	}

	.button.learn-more {
		margin-top: 0.25rem;
	}

	.button.subscribe {
		width: 100%;
		padding-right: 2.5rem;
		padding-left: 1rem;
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-ms-flex-order: -1;
		order: -1;
		font-size: 0.9rem;
	}

	.container {
		padding-right: 0px;
		padding-left: 0px;
	}

	.nav-section {
		height: 132px;
		box-shadow: none;
	}

	.nav-container {
		height: 3.25rem;
	}

	.nav-link {
		font-size: 1rem;
	}

	.nav-link.two-part-mobile {
		width: 75%;
	}

	.nav-link.no-arrow.extra {
		border-top: 2px solid #48bdd7;
		border-bottom-width: 1px;
	}

	.logo {
		margin-right: auto;
		margin-left: auto;
	}

	.logo.w--current {
		margin-top: 0.75em;
		margin-bottom: 0.75em;
	}

	.row.footer-row {
		margin-right: 0px;
		margin-left: 0px;
	}

	.logo-text {
		font-size: 1.05rem;
		line-height: 1.35rem;
	}

	.menu-button {
		width: 25%;
		height: 3.25rem;
		padding-right: 1rem;
		padding-left: 1rem;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.footer-column-wrap {
		padding-right: 0rem;
		-webkit-flex-basis: 100%;
		-ms-flex-preferred-size: 100%;
		flex-basis: 100%;
	}

	.footer-column-wrap._1 {
		width: 100%;
		max-width: 100%;
	}

	.footer-column-wrap._2 {
		width: 100%;
		max-width: 100%;
	}

	.footer-column-wrap._3 {
		width: 100%;
		max-width: 100%;
	}

	.header-content-right-wrap {
		display: none;
		margin-right: 0em;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.header-section {
		height: 80px;
	}

	.header-menu-wrap {
		height: 3.25rem;
	}

	.form-field.header-search-field {
		padding-right: 28%;
	}

	.secondary-nav-list {
		max-width: 75%;
	}

	.secondary-nav-list-item {
		max-width: 100%;
	}

	.right-sidebar-column {
		margin-top: 1rem;
		padding-top: 1rem;
	}

	.secondary-nav-text {
		display: none;
	}

	.secondary-nav-list-link {
		width: 5rem;
		padding-left: 2em;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.slider {
		height: 815px;
		padding-top: 132px;
		background-image: -webkit-linear-gradient(270deg, #fff, #fff);
		background-image: linear-gradient(180deg, #fff, #fff);
	}

	.slider-arrow {
		top: 74px;
		height: 358px;
		max-width: 50px;
		font-size: 1.25rem;
	}

	.slider-indicator {
		top: 734px;
		height: 50px;
	}

	.main-section {
		padding-top: 0rem;
		padding-bottom: 0rem;
		background-color: transparent;
	}

	.slider-image {
		height: 300px;
	}

	.slider-image .slider-slide-image {
		background-size: 233%;
		height: 300px;
	}

	.inside-content-beckground {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.main-content-wrap.no-sidebars {
		margin-right: 0px;
		margin-left: 0px;
	}

	.menu-button-icon {
		display: block;
		margin-right: auto;
		margin-left: auto;
		float: none;
		line-height: 3.25rem;
	}

	.menu-button-text {
		display: none;
	}

	.slider-arrow-icon {
		top: 305px;
	}

	.wide-background-block.content-box-background {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.slider-caption-title {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
		font-size: 1em;
	}

	.pagination-middle-wrap {
		min-width: 100%;
		margin-top: 0.75em;
		padding-top: 0.75em;
		border-top: 1px solid rgba(84, 159, 196, 0.25);
	}

	.loc-type-form-checkbox {
		width: 100%;
	}

	.loc-type-form-checkbox.sidebar-checkbox {
		width: 100%;
	}

	.two-part-button-toggle {
		width: 25%;
		height: 100%;
		padding-top: 1.25em;
		padding-bottom: 1.1em;
		line-height: 1.625rem;
	}

	.footer-container {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.main-content-container {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.slide-nav.heading {
		position: relative;
		bottom: 25px;
		background-image: -webkit-linear-gradient(270deg, #09182e, #09182e);
		background-image: linear-gradient(180deg, #09182e, #09182e);
	}

	.slider-content-wrapper {
		height: 300px;
	}

	.mini-slider-wrapper {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
		background-image: -webkit-linear-gradient(270deg, #09182e, #09182e);
		background-image: linear-gradient(180deg, #09182e, #09182e);
	}

	.mini-slider-text-wrapper {
		height: auto;
		padding-right: 1.25rem;
		padding-bottom: 4rem;
		padding-left: 1.25rem;
	}

	.row-wrapper {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.column-heading {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.unordered-list {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.text-block-homepage-paddings,
	.text-block-homepage {
		font-size: 0.9rem;
	}

	.report-list-item {
		font-size: 0.9rem;
	}

	.photo-image-section {
		height: 285px;
		background-image: var(--rotunda-bg-image);
		background-position: 50% 50%;
		background-size: cover;
		background-repeat: no-repeat;
		background-attachment: fixed;
	}

	.content-container {
		margin: 1.25rem;
		background-image: none;
	}

	.portrait-image {
		width: 150px;
	}

	.our-mission-heading {
		font-size: 1.8rem;
	}

	.mission-text {
		margin-right: 5%;
		margin-left: 5%;
		padding-right: 0rem;
		padding-bottom: 0.5rem;
		padding-left: 0rem;
		font-size: 0.9rem;
	}

	.color-banner {
		height: 140px;
		margin-top: 70px;
		line-height: 1.5rem;
	}

	.mission-wrapper {
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}

	.quicklink-links {
		font-size: 0.9rem;
	}

	.arrow-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
	}

	.news-text {
		font-size: 0.9rem;
	}

	.caption-box {
		height: 300px;
		padding: 1.25rem;
	}

	.slider-caption-title-heading {
		font-size: 1.35rem;
		line-height: 1.85rem;
	}

	.caption-text {
		margin-bottom: 0.25rem;
		font-size: 0.9rem;
	}

	.current-topics-heading-link {
		font-size: 0.9rem;
	}

	.filter-type-wrapper {
		width: 100%;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.filter-type-wrapper.search {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.filter-type-wrapper.entity {
		padding-right: 0.5rem;
	}

	.filter-type-wrapper.audit-category {
		padding-left: 0.5rem;
	}

	.filter-type-wrapper.release-date {
		width: 100%;
		padding-right: 0rem;
	}

	.filter-type-wrapper.fiscal-year {
		width: 100%;
		padding-left: 0rem;
	}

	.filter-type-wrapper.city {
		width: 100%;
		padding-right: 0rem;
	}

	.filter-type-wrapper.fiscal-year {
		width: 100%;
		padding-right: 0rem;
	}

	.filter-heading.filter-subheading {
		width: 10%;
		text-align: center;
	}

	.filter-dd-toggle {
		width: 100%;
		height: 35px;
	}

	.filter-wrapper .hasDatepicker,
	.date-picker-field {
		width: calc(100% - 21px);
		height: 35px;
		padding-left: 6px;
		font-size: 0.75rem;
	}

	.filter-wrapper .ui-form-datepicker {
		width: 100%;
		height: 35px;
		padding-left: 0;
		font-size: 0.75rem;
	}

	.pagination-wrapper {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.pagination-block-left {
		width: 50%;
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-ms-flex-order: -1;
		order: -1;
	}

	.pagination-block-middle {
		width: 100%;
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
	}

	.pagination-block-right {
		width: 50%;
	}

	.page-picker-wrap._1 {
		width: 100%;
	}

	.pagination-form {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.detail-item-wrapper {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.details-title {
		padding-bottom: 0.25rem;
	}

	.pdf-excerpt-div {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.pagination-wrapper {
		display: block;
	}

	.pagination-wrapper .page-picker-wrap {
		margin-bottom: 15px;
		align-items: center;
		justify-content: center;
	}

	.pagination-form {
		display: block;
		width: 100%;
	}
}

/* Set root font size */
html {
	font-size: 1rem;
}

/* Set font render */
body {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
}

/* Superscript */
sup {
	font-size: xx-small;
}

/* COLLAPSIBLE STICKY HEADER  */
@media screen and (min-width: 992px) {
	.sticky.nav-section {
		position: fixed;
		top: 0;
		height: 160px;
	}

	.sticky .wrap {
		padding-top: 0rem;
		padding-bottom: 0.75rem;
		height: 125px;
	}

	.sticky .logo-image {
		height: 5.25rem;
		width: 5.25rem;
	}

	.sticky .logo-text {
		font-size: 1.35rem;
		line-height: 1.35rem;
	}

	.sticky .nav-menu-wrap {
		height: 2.75rem;
	}

	.sticky .header-menu-wrap {
		height: 2.75rem;
	}

	.sticky .nav-link {
		padding-top: 0.7rem;
		padding-bottom: 0.7rem;
		font-size: 0.95rem;
	}

	.sticky .nav-drop-wrap .nav-drop-toggle-link,
	.sticky .nav-drop-toggle-link {
		padding-top: 0.7rem;
		padding-bottom: 0.7rem;
		font-size: 0.95rem;
	}

	.sticky .nav-container {
		height: 2.75rem;
		margin-top: 0px;
	}
}

/* --- Style Nav Slider Dots --- */
/* .w-num .w-round or omit for square*/
.w-slider-nav.w-round > div {
	background: rgba(255, 255, 255, 0.5);
}

.w-slider-dot.w-active {
	background: #ffffff !important;
}

/* Footer phone number auto-styling on mobile devices */
.footer-section a[href^=tel] {
	-webkit-transition: all 250ms ease;
	text-decoration: none;
	color: #8ec8e4;
	padding-bottom: 0;
	border-bottom: 1px solid rgba(73, 143, 163, 0.35);
}

.footer-section a[href^=tel]:hover {
	color: #fff;
	padding-bottom: 0;
}

/* Adjust heading styles when they're set as links */
xh1 > a {
	color: inherit;
	background-image: none;
}

xh1 > a:hover {
	color: inherit;
	background-image: none;
}

/* Text selection style for Mozilla based browsers */
::-moz-selection {
	background: #e3e3e3;
	color: #000;
}

/* Text seclection style for Safari browser */
::selection {
	background: #e3e3e3;
	color: #000;
}

x.w-slider-nav > div {
	border: white 3px solid;
	background: black;
}

x.w-slider-nav > div:hover {
	border: white 3px solid;
	background: black;
}

x.w-slider-nav > div.w-active {
	border: white 3px solid;
	background: white;
}

x.w-slider-nav > div.w-active:hover {
	border: white 3px solid;
	background: white;
}

/*mmenu*/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	html.mm-opening .mm-menu ~ .mm-slideout {
		-webkit-transform: translate(0, 0);
		-ms-transform: translate(0, 0);
		transform: translate(0, 0);
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate(0, 0px);
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu.mm-opened {
		display: block !important;
		width: 80% !important;
		margin: 0;
		background-color: transparent;
		background-image: -webkit-linear-gradient(270deg, rgba(46, 95, 108, 0.5), rgba(46, 95, 108, 0.5)), -webkit-linear-gradient(270deg, #09182e, #09182e);
		background-image: linear-gradient(180deg, rgba(46, 95, 108, 0.5), rgba(46, 95, 108, 0.5)), linear-gradient(180deg, #09182e, #09182e);
	}

	.mm-menu.mobile-navigation-menu {
		width: 80%;
		max-width: none;
		min-width: 0;
		height: 100%;
	}

	html.mm-opening .mm-menu ~ .mm-slideout {
		-webkit-transform: translate(80%, 0);
		-ms-transform: translate(80%, 0);
		transform: translate(80%, 0);
		-webkit-transform: translate3d(80%, 0, 0);
		transform: translate(80%, 0px);
	}

	.mm-listview {
		padding: 0;
	}
	/*remove default link styles*/
	.mm-listview > li > a,
	.mm-listview > li > span {
		margin-right: 0px;
		margin-bottom: 0px;
		padding: 1em 1rem 1em 2rem;
		border-bottom: 1px solid rgba(9, 24, 46, 0.5);
		box-shadow: none;
		line-height: 1.375rem;
		text-align: left;
		z-index: 140;
		display: block;
		text-transform: uppercase;
		width: 100%;
		font-family: Quicksand, sans-serif;
		color: #fff;
		font-size: 1rem;
		font-weight: 400;
	}

	.mm-listview > li > a:not(.mm-next):hover,
	.mm-listview > li > span:hover {
		background-color: #2e5f6c;
		box-shadow: none;
	}

	.mm-menu .mm-listview > li.mm-selected > a:not(.mm-next),
	.mm-menu .mm-listview > li.mm-selected > span {
		background-color: transparent;
		box-shadow: inset 5px 0 0 0 #48bdd7;
	}
	/*remove default borders*/
	.mm-listview > li:not(.mm-divider)::after {
		border-bottom-width: 0;
	}
	/*next links*/
	.mm-listview > li .mm-next::before {
		border-left-color: transparent;
	}

	.mm-listview .mm-next {
		width: 20%;
		box-shadow: none;
		border-bottom: 1px solid transparent !important;
		background-color: rgba(0, 0, 0, 0.15);
	}

	.mm-listview .mm-next.mm-fullsubopen {
		width: 20%;
	}

	.mm-listview .mm-next:hover {
		background: rgba(0, 0, 0, 0.25);
	}

	.mm-listview .mm-next + a,
	.mm-listview .mm-next + span {
		width: 80%;
		margin-right: 20%;
		pointer-events: none;
	}
	/*next link arrow*/
	.mm-next::after,
	.mm-arrow::after {
		right: 45%;
		height: 8px;
		width: 8px;
	}

	.mm-menu .mm-listview > li .mm-next::after,
	.mm-menu .mm-listview > li .mm-arrow::after {
		border-color: #ccc;
	}
	/*second level and previous links*/
	.mm-prev::before {
		left: 35px;
	}

	.mm-panels > .mm-panel > .mm-navbar + .mm-listview {
		margin-top: -5px;
	}

	.mm-navbar {
		height: 55px;
		box-shadow: none;
		margin-left: 0;
		margin-right: 0;
		padding: 0.1rem 1em 0.875em 3.5rem;
		color: #fff;
		border-bottom: 1px solid rgba(9, 24, 46, 0.5);
	}

	.mm-navbar .mm-btn {
		height: 55px;
		box-shadow: none;
		border-bottom: 1px solid rgba(9, 24, 46, 0.5);
	}

	.mm-menu .mm-navbar .mm-btn::before,
	.mm-menu .mm-navbar .mm-btn::after {
		border-color: #fff;
	}

	.mm-navbar .mm-title {
		color: #fff !important;
		padding: 0.9em 0 1em 0;
		text-transform: uppercase;
		font-size: 1rem;
		line-height: 1.375rem;
		font-weight: 400;
		text-decoration: none;
		text-transform: uppercase;
		box-shadow: none;
		text-align: left;
		border-bottom-width: 0;
	}

	.mm-navbar .mm-title:hover,
	.mm-navbar .mm-btn:hover {
		box-shadow: none;
		text-decoration: none;
	}
}

/* breakpoints */
[data-breakpoints] {
	display: none !important;
}

@media screen and ( min-width: 1106px ) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and ( min-width: 735px ) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/*inside page*/
/*left column*/
.left-sidebar-custom {
	padding: 1.5rem 1rem 0;
	border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.left-sidebar-custom h1,
.left-sidebar-custom h2,
.left-sidebar-custom h3,
.left-sidebar-custom h4 {
	margin-top: 0em;
	margin-bottom: 0.75em;
	font-family: 'Crete Round', sans-serif;
	color: #2b4972;
	font-size: 1.25rem;
	line-height: 1.75rem;
	font-weight: 400;
	letter-spacing: 0.01rem;
}

@media (max-width: 767px) {
	.left-sidebar-custom h1,
	.left-sidebar-custom h2,
	.left-sidebar-custom h3,
	.left-sidebar-custom h4 {
		font-size: 1.125rem;
		line-height: 1.5em;
	}
}

/*right column*/
.w-col .right-sidebar-column {
	padding-left: 10px;
}

.right-sidebar-column h1,
.right-sidebar-column h2,
.right-sidebar-column h3,
.right-sidebar-column h4 {
	margin-top: 0em;
	margin-bottom: 0.75em;
	font-family: 'Crete Round', sans-serif;
	color: #2b4972;
	font-size: 1.25rem;
	line-height: 1.75rem;
	font-weight: 400;
	letter-spacing: 0.01rem;
}

.box.documents .content > table {
	margin-bottom: 15px;
}

.box.documents .content td:first-of-type {
	padding-right: 10px;
	width: 35px;
}

.box .contentbox_item {
	margin-bottom: 15px;
}

@media (max-width: 991px) {
	.w-col .right-sidebar-column {
		padding-left: 0;
	}
}

@media (max-width: 767px) {
	.right-sidebar-column h1,
	.right-sidebar-column h2,
	.right-sidebar-column h3,
	.right-sidebar-column h4 {
		font-size: 1.125rem;
		line-height: 1.5em;
	}
}

/*skip to main content*/
.skip-to-main:focus {
	width: auto;
	height: auto;
	text-indent: 0;
	z-index: 1001;
	display: inline-block;
	padding: 0.5rem 3rem 0.5rem 1.5rem;
	background-color: #27758a;
	background-image: url("/20180504170300/assets/images/button_arrow-pointing-right-02.svg");
	background-position: 93% 50%;
	background-size: 25px 25px;
	background-repeat: no-repeat;
	color: #fff;
	font-weight: 400;
	text-align: center;
	letter-spacing: 0.05rem;
	text-decoration: none;
}

/*messages*/
#session-message-wrapper .success {
	display: block;
	text-align: left;
	padding: 20px;
	margin-top: 20px;
	margin-bottom: 20px;
	background-color: #377c16;
	color: #fff;
	font-size: 1rem;
	line-height: 1.5rem;
}

#session-message-wrapper .error {
	display: block;
	text-align: left;
	padding: 20px;
	margin-top: 20px;
	margin-bottom: 20px;
	background-color: #d12c16;
	color: #fff;
	font-size: 1rem;
	line-height: 1.5rem;
}

#session-message-wrapper .success p,
#session-message-wrapper .success ul li,
#session-message-wrapper .error p,
#session-message-wrapper .error ul li {
	margin-bottom: 0;
	margin-top: 0;
	font-weight: 400;
	color: #fff;
	font-size: 1rem;
	line-height: 1.5rem;
}

.ui-form-error {
	display: none;
}

/*tables*/
table.styled {
	width: 100%;
	margin: 20px 0 10px 0;
	background-color: transparent;
	border-spacing: 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid white;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #09182e;
	color: white;
}

table.styled th {
	font-weight: normal;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #2e5f6c;
	color: white;
}

table.styled th a {
	color: inherit;
	text-decoration: underline;
	border-bottom: none;
	font-weight: 400;
}

table.styled th a:hover {
	color: inherit;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
}

table.styled.striped tr:nth-child(even) {
	background: #f2f2f2;
}

table.styled.striped tr:nth-child(odd) {
	background: transparent;
}

table.styled.striped tr.even {
	background: #f2f2f2 !important;
}

table.styled.striped tr.odd {
	background: transparent !important;
}

@media (max-width: 875px) and (min-width: 39.9376em) {
	table.reports th,
	table.reports td {
		font-size: 0.85rem !important;
	}
}

/*tablesaw*/
@media (max-width: 39.9375em) {
	table.tablesaw.styled {
		border: 1px solid #dfdfdf;
	}

	table.tablesaw.styled tbody {
		border-top: 1px solid #dfdfdf;
	}

	table.tablesaw.styled th,
	table.tablesaw.styled td {
		border-right: 0 none;
	}

	table.reports tr.expert-table-row td .tablesaw-cell-content {
		width: 100%;
	}
}

/*images*/
img {
	height: auto;
}

td img {
	max-width: none;
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

/*forms*/
.cms_label,
.label,
label {
	font-size: 1rem;
	font-weight: 400;
}

label {
	display: inline;
	margin-bottom: 0;
}

input[type="text"],
input[type="tel"],
input[type="password"],
input[type="email"],
input[type="search"],
textarea,
select {
	font-size: 0.875rem;
	line-height: 1.42857143;
	padding: 4px 10px;
	border: 1px solid #666;
	color: #333333;
	border-radius: 0;
}

input[type="search"] {
	padding: 8px 10px;
}

textarea {
	resize: vertical;
}

/*blockquotes*/
blockquote {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1.75rem 1.5rem;
	border-left: 4px solid #498fa3;
	background-color: #f2f2f2;
	color: #444;
	font-family: Quicksand, sans-serif;
	font-size: 1.0625rem;
	line-height: 1.5rem;
	font-weight: 400;
}

blockquote p {
	margin-bottom: 0;
	margin-top: 0;
	font-size: inherit !important;
	line-height: inherit;
	color: inherit;
	font-family: inherit;
}

/*horizontal ruler*/
.cms_divider,
.cms_item_divider,
.item_divider,
.separator,
#services_module.list .list_item .footer,
#submissionforms_module.cms_form .formBreak,
.module-forms .formBreak {
	height: 1px !important;
	margin-top: 1.5rem !important;
	margin-bottom: 1.5rem !important;
	background-color: rgba(0, 0, 0, 0.15) !important;
	border-top: 0 none !important;
	border-bottom: 0 none !important;
}

.collection > .node {
	border-top-style: solid;
	border-top-color: rgba(0, 0, 0, 0.15);
	margin: 1.5rem 0 0 0;
	padding: 1.5rem 0 0 0;
}

.section_separator {
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

hr:not(.fc-divider):not(.formBreak) {
	border-color: rgba(0, 0, 0, 0.15);
	border: 0px;
	color: rgba(0, 0, 0, 0.15);
	margin-bottom: 1.5em;
	margin-top: 1.5em;
	border-top: 1px solid;
}

/*links*/
.no-border-link {
	border-bottom: 1px solid transparent !important;
}

/*heading links*/
.left-sidebar-custom h1 a,
.inside-row-nested h1 a,
.left-sidebar-custom h2 a,
.inside-row-nested h2 a,
.left-sidebar-custom h3 a,
.inside-row-nested h3 a,
.left-sidebar-custom h4 a,
.inside-row-nested h4 a,
.left-sidebar-custom h5 a,
.inside-row-nested h5 a,
.left-sidebar-custom h6 a,
.inside-row-nested h6 a {
	border-bottom-color: rgba(72, 189, 215, 0.35);
	color: #498fa3;
}

.left-sidebar-custom h1 a:hover,
.inside-row-nested h1 a:hover,
.left-sidebar-custom h2 a:hover,
.inside-row-nested h2 a:hover,
.left-sidebar-custom h3 a:hover,
.inside-row-nested h3 a:hover,
.left-sidebar-custom h4 a:hover,
.inside-row-nested h4 a:hover,
.left-sidebar-custom h5 a:hover,
.inside-row-nested h5 a:hover,
.left-sidebar-custom h6 a:hover,
.inside-row-nested h6 a:hover {
	color: #2b4972;
	border-bottom-color: rgba(72, 189, 215, 0.35);
}

/*module styling*/
/****************** Accounts ************************/
/***************************************************/
#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 400px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_buttons {
	margin-top: 0;
}

#account_module.form .form_container input[type="password"] {
	margin-bottom: 10px;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	margin: 20px 0;
	padding-bottom: 7px;
	color: #2b4972;
	font-size: 1.875rem;
	line-height: 2.375rem;
	font-weight: 400;
	letter-spacing: 0.01rem;
	font-family: 'Crete Round', sans-serif;
}

/*edit address form*/
#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #ff0000;
	font-size: inherit;
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url("/core/public/shared/assets/images/required.gif");
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

/****************** FAQs *********************/
/***********************************************/
#faqs_module .cms_list_item ul,
#faqs_module .cms_list_subitem ul {
	color: #444;
	list-style-type: disc;
}

#faqs_module.cms_list .cms_list_item:first-of-type {
	padding-top: 0;
}

#faqs_module.cms_list .cms_list_item:first-of-type .cms_title h3,
#faqs_module.cms_list .cms_list_subitem:first-of-type .cms_title h3 {
	margin-top: 0;
}

#faqs_module.cms_list .cms_list_item .cms_title h3,
#faqs_module.cms_list .cms_list_subitem .cms_title h3 {
	margin-bottom: 5px;
}

.box.tools .content .contentbox_item form input[type="text"] {
	width: 100% !important;
	line-height: 1.5em;
	padding: 0.25em;
}

.box.tools .content .contentbox_item form > p {
	margin-bottom: 0;
}

#faqs_module.cms_list .cms_list_item .description,
#faqs_module.cms_list .cms_list_subitem .description {
	padding-top: 0;
}

#faqs_module.cms_list .cms_list_item .description,
#faqs_module.cms_list .cms_list_subitem .description {
	color: #000;
	list-style-type: none;
}

#faqs_module.cms_list .cms_list_subitem.vertical_spacer {
	padding-top: 0;
}

#faqs_module.cms_list .cms_list_subitem.vertical_spacer .cms_title h3 {
	margin-top: 0;
}

/******************** Search *******************/
/***********************************************/
.collection.search-collection .search-field-title {
	line-height: 1.5em;
}

/*************** Submission Forms **************/
/***********************************************/
#submissionforms_module.cms_form > .cms_divider {
	display: none;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding: 1px 27px;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span a {
	border-bottom: 0px;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 4px 10px;
}

#submissionforms_module.cms_form .cms_field input.hasDatepicker[size="60"][type="text"] {
	display: inline-block;
	width: 100%;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

/*confirmation page*/
.module-forms.confirmation .step-title {
	margin-top: 1rem;
	font-family: 'Crete Round', sans-serif;
	color: #2b4972;
	font-size: 1.875rem;
	line-height: 2.375rem;
	font-weight: 400;
	letter-spacing: 0.01rem;
}

.module-forms.confirmation .value-cell img.signature-image {
	max-width: 100%;
}

@media (max-width: 991px) {
	.module-forms.confirmation td {
		display: block;
		width: 100%;
	}

	.module-forms.confirmation .label-cell {
		padding-bottom: 0;
	}

	.module-forms.confirmation .value-cell {
		padding-top: 0;
	}
}

/*dynamic content boxes*/
/*submission forms*/
#submissionforms_module.homepage_contentbox {
	text-align: left;
}

#submissionforms_module.homepage_contentbox .cms_divider {
	display: none;
}

#submissionforms_module.homepage_contentbox .cms_label {
	padding-bottom: 5px;
	font-size: 0.875rem;
	line-height: 1.25rem;
}

#submissionforms_module.homepage_contentbox label {
	font-size: 0.875rem;
	line-height: 1.25rem;
	display: inline-block;
}

#submissionforms_module.homepage_contentbox .ui-datepicker-trigger {
	vertical-align: baseline;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.homepage_contentbox .cms_field input[type="text"],
#submissionforms_module.homepage_contentbox .cms_field input[type="tel"],
#submissionforms_module.homepage_contentbox .cms_field input[type="password"],
#submissionforms_module.homepage_contentbox .cms_field input[type="email"],
#submissionforms_module.homepage_contentbox .cms_field input[type="date"],
#submissionforms_module.homepage_contentbox .cms_field select,
#submissionforms_module.homepage_contentbox .cms_field textarea {
	width: 100%;
}

#submissionforms_module.homepage_contentbox .cms_field input.hasDatepicker[type="text"] {
	width: calc(100% - 20px);
}

#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .cms_hint {
	color: #444;
	font-family: inherit;
}

@media (max-width: 767px) {
	#submissionforms_module.homepage_contentbox {
		padding: 0 15px;
	}

	#submissionforms_module.homepage_contentbox tr[data-required="true"] .cms_label label::after,
	#submissionforms_module.homepage_contentbox tr[data-required="true"] .cms_label div::after {
		content: "";
		padding: 0;
	}
}


/* footer dislcaimer */
.footer-disclaimer {
	margin-bottom: 20px;
}

.footer-disclaimer p,
.footer-disclaimer li,
.footer-disclaimer span,
.footer-disclaimer td {
	font-family: Quicksand, sans-serif;
	color: hsla(0, 0%, 100%, 0.75);
}

.footer-disclaimer h1,
.footer-disclaimer h2,
.footer-disclaimer h3,
.footer-disclaimer h4,
.footer-disclaimer h5,
.footer-disclaimer h6 {
	color: #fff;
	font-family: Quicksand, sans-serif;
	margin-bottom: 0px;
	margin-top: 7px;
}

.footer-disclaimer a {
	font-family: Quicksand, sans-serif;
	color: #8ec8e4;
}

.footer-disclaimer a:hover {
	color: #fff;
	font-family: Quicksand, sans-serif;
}
