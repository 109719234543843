/* ISSUE: Ensure that all information conveyed with color is also available without color */

/* /core/public/shared/assets/css/responsive.css */
.pagination-wrapper .pagination-previous-page .disabled,
.pagination-wrapper .pagination-next-page .disabled {
	text-decoration: line-through;
}

/* /core/website/modules/submissionforms/css/screen.css */
#submissionforms_module.cms_form .cms_steps_wrapper .active,
#submissionforms_module.cms_form .cms_steps_wrapper .last_active {
	font-weight: 700;
}

#submissionforms_module.cms_form .cms_buttons .cms_form_button:hover {
	cursor: pointer;
}

#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button:hover {
	cursor: pointer;
}

/* ISSUE: Ensure that foreground and background colors have enough contrast */

/* /core/libraries/tablesaw/assets/2.0.3/tablesaw.bare.css */
.tablesaw-enhanced .tablesaw-bar .btn:hover,
.tablesaw-enhanced .tablesaw-bar .btn:focus {
	color: #1879c3;
	background-color: #fff;
	outline: 1px dotted #000;
	cursor: pointer;
}

/* /core/public/shared/assets/css/responsive.css */
.fly-out-menu-list-container ul.fly-out-menu-list li a:active,
.fly-out-menu-list-container ul.fly-out-menu-list li.selected a {
	background-color: #1f78d1 !important;
	color: #ffffff !important;
	cursor: pointer;
}

/* /core/public/shared/assets/css/screen.css */
.search-facets .search-facet .price-range-button a {
	background-color: #757575;
	color: #ffffff;
	text-transform: uppercase;
}

/* ISSUE: Use relative rather than absolute units in CSS property values */

/* /core/libraries/select2/assets/select2.css */
.select2-container .select2-choice abbr {
	font-size: 0.2rem;
}

/* /core/public/shared/assets/css/responsive.css */
.fly-out-menu-container .fly-out-menu-button .glyph-keyboard_arrow_down {
	font-size: 1.25rem;
}

/* /core/website/modules/submissionforms/css/print.css */
.module-forms.confirmation .step-title {
	font-size: 0.875rem;
}

/* /core/website/modules/submissionforms/css/screen.css */
#submissionforms_module.cms_form .cms_hint {
	font-size: 0.6875rem;
}

#submissionforms_module.cms_form .cms_date_hint {
	font-size: 0.6875rem;
}

#submissionforms_module.cms_form .agreement_hint {
	font-size: 0.6875rem;
}

.module-forms.confirmation .step-title {
	font-size: 1rem;
}

#submissionforms_module.homepage_contentbox .cms_hint,
#submissionforms_module.homepage_contentbox .cms_date_hint {
	font-size: 0.6875rem;
}

#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.6875rem;
}

.module-forms .ui-form-field-payment-information,
.module-forms .ui-data-addresses h3 {
	font-size: 0.875rem;
}